export const userLocation = async (regions) => {
    const shhhh = "5FeeMMNq0pC4YNt";
  
    const response = await fetch(
      `https://pro.ip-api.com/json/?key=${shhhh}&fields=status,countryCode,query&lang=`
    );
    const data = await response.json();
    console.log(data);
    const match = regions.reduce((match, region) => {
      var region_codes_list = region?.iso_codes.split(",");
      if (region_codes_list.includes(data.countryCode)) {
        match = region;
      }
      return match;
    }, null);
  
    return match || regions[4];
  };
  