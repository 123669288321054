import React, { useEffect } from "react";
import generateLink from "../../../utils/helpers/generateLink";
import "./tabs.css";

// import dummyImage from '../'
export default function TabSection({ content }) {
  const { header, description, tabs } = content;

  const openCity = (evt, cityName) => {
    let defaultActive = document.getElementById("defaultOpen");
    if (defaultActive.classList.contains("active")) {
      defaultActive.classList.remove("active");
    }
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  };

  useEffect(() => {
    document.getElementById("tab_1").style.display = "block";
    let link = document.getElementById("defaultOpen");
    link.classList.add("active");
  }, []);

  return (
    <section className="home_tabs_container">
      <div className="header_home_tabs">
        <h2>{header}</h2>
        <p dangerouslySetInnerHTML={{ __html: description }}></p>
      </div>
      <ul className="home_tab">
        {tabs.map((tab, index) => {
          return (
            <li key={index}>
              <button
                className="tablinks"
                onClick={(event) => openCity(event, `tab_${index + 1}`)}
                id="defaultOpen"
              >
                {tab.header}
              </button>
            </li>
          );
        })}
      </ul>
      <div className="home_tab_section">
        {tabs.map((tab, index) => {
          return (
            <div  key={index} id={`tab_${index + 1}`} className="tabcontent">
              <div className="tab_body">
                <div className="tab_left_photo">
                  <img
                    src={tab.image.source}
                    className="imgResponsive"
                    alt={tab.image.alt}
                  />
                </div>
                <div className="tab_right_content">
                  <h3>{tab.header}</h3>
                  <p dangerouslySetInnerHTML={{ __html: tab.description }}></p>
                  {tab.button.text && generateLink(tab.button.url, tab.button.text, "blue_btn")}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}
