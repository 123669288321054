import formatCTAs from "../formatCTAs";

const formatHeroTwo = (heroTwoData, labels, fpoBox) => {
  const data = heroTwoData || {};
  const type = data.type;
  const header = data.header;
  const subHeader = data.subheader;
  const images = {
    desktop: {
      src: data?.image_large?.source || fpoBox,
      alt: data?.image_large?.alt || "placeholder",
    },
    mobile: {
      src: data?.image_medium?.source || fpoBox,
      alt: data?.image_medium?.alt || "placeholder",
    },
  };
  const theme = data.theme;
  const ctas = formatCTAs(data.ctas);

  const content = {
    header,
    subHeader,
    images,
    ctas,
  };
  return {
    content,
    type,
    theme,
  };
};

export default formatHeroTwo;
