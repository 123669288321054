import { SplitButton } from "react-bootstrap";
import regionLanguageMapping from "./regionLanguageMapping";
import selectedLanguage from "./selectedLanguage";
import selectedRegion from "./selectedRegion";

const formatLink = (link) => {
  if (link && link[0] === "/") {
    // if(selectedLanguage()=='en'){
    //   link = link.replace("/en/", "/");
    //   return `/${selectedLanguage() + '-' + selectedRegion().region_code}${link}`;
    // }else{
    //   return `/${selectedLanguage()}${link}`;
    // }

    // check if language is en but region is not an en region
    let enRegions = ["us", "eu", "au"];
    if(selectedLanguage()=='en' && !enRegions.includes(selectedRegion().region_code)){
      link = link.replace("/"+selectedLanguage()+"/", "/");
      return `/${regionLanguageMapping[selectedRegion().name] + '-' + selectedRegion().region_code}${link}`;
    }else{
      link = link.replace("/"+selectedLanguage()+"/", "/");
      return `/${selectedLanguage() + '-' + selectedRegion().region_code}${link}`;
    }
  } else {
    return link;
  }
}

export default formatLink;
