import { useState, useEffect } from "react";
import backendURL from "../utils/helpers/backendURL";

const useRoutes = () => {
  // Fetches data for routing
  // This is used for setting up custom routes (see Routing.js) as well as helping to setup custom fetching, formatting, and url setting for said pages
  const [routesData, setRoutesData] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(
          `${backendURL()}/api-v1/FetchAllURLandType?_format=json`
        );
        const data = await response.json();
  //  console.log(data)
        // TODO - Format this data per site requirements
        setRoutesData(data);
      } catch (e) {
        console.log(e);
        setRoutesData("error");
      }
    };

    if (!routesData) {
      getData();
    }
  });

  return routesData;
};

export default useRoutes;
