
const formatListFeatFive = (
  listFeatFiveData,
  labels,
  fpoBox,
) => {
  const data = listFeatFiveData || {};
  const {header, subHeader} = data;
 
  const iconListItems = formatListFeatFiveItems(data?.list);
  
  const content = {
    header,
    subHeader,
    iconListItems,
    type: "list_feat_5",
  };

  const anchor = data.anchor_toggle && data.anchor_text ? data.anchor_text : null;

  const theme = data.theme;
  
  const type = "listfeat_5";
  return { content, theme, type, anchor };
};

const formatListFeatFiveItems = (items = []) => {
  return items.map((item) => {
    return {
      type: item.icon,
      header: item.header,
      text: item.text,
    };
  });
};

export default formatListFeatFive;
