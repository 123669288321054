import React, { useEffect, useState } from "react";
import { FilterHero, Hero5 } from "luna-one";
import EventCard from "../../CustomComponents/Event/EventCard";
import Pagination from "../../Pagination/Pagination";
import Loading from "../../Loading/Loading";
import getEventByIndustry from "../../../utils/helpers/getEventByIndustry";
import Stack from "../../Stack/Stack";
import selectedRegion from "../../../utils/helpers/selectedRegion";

const PER_PAGE = 10;
export default function EventLanding({ content }) {
  let calendars = {};

  if (selectedRegion().name === "Finland") {
    calendars = {
      Julkishallinto:
        "trimble.com_bu4ig1pmik3llmcdfk094s25f4@group.calendar.google.com",
      Utilities:
        "trimble.com_6bgukvntv7do0drfcm8vae2abk@group.calendar.google.com",
    };
  } else if (selectedRegion().name === "North America" || selectedRegion().name === "Australia & New Zealand") {
    calendars = {
      Electric: "c_tjj1326bo0s9dfakp8o1hnffuk@group.calendar.google.com",
    };
  }

  const {
    calendar_id,
    name,
    sub_header,
    content_sections,
    applicationData,
    NoRecordsFoundData,
  } = content;
  const [events, setEvents] = useState([]);
  const [data, setData] = useState([]);
  const [selectedCalender, setSelectedCalender] = useState(null);
  const [loading, setLoading] = useState(true);

  const script = document.createElement("script");
  script.src = "https://apis.google.com/js/api.js";
  document.body.appendChild(script);

  useEffect(() => {
    setTimeout(() => {
      loadEvents();
    }, 4000);
  }, []);

  function loadEvents() {
    var gapi = window.gapi;
    gapi.load("client", getAllEvents);
  }

  async function getAllEvents() {
    getEventByIndustry(calendar_id)
      .then((response) => {
        setEvents(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    const getCalenderEvents = (selectedCalender) => {
      getEventByIndustry(selectedCalender)
        .then((response) => {
          setEvents(response);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    };
    if (selectedCalender) {
      getCalenderEvents(selectedCalender);
    }
  }, [selectedCalender]);

  function handleDropdownChange(selection) {
    setLoading(true);
    if (selection === "hide") {
      getAllEvents();
    } else {
      const output = Object.keys(calendars).filter(
        (calender) => calender === selection
      );

      const result = events.filter((choice) => {
        return choice.organizer.displayName === selection;
      });
      setSelectedCalender(calendars[output]);
      setEvents(result);
    }
  }

  const dropdownOne = {
    options: Object.keys(calendars),
    defaultText: applicationData?.allLabelText || "All",
    name: "Category",
  };

  return (
    <>
      <FilterHero
        header={name}
        text={sub_header}
        // dropdowns={[dropdownOne]}
        handleDropdownSelect={handleDropdownChange}
        handleDropdownChange={handleDropdownChange}
        backgroundColor="#002D5B"
        theme="Dark Blue"
      />
      {loading && <Loading />}
      {content_sections.length > 0 && <Stack content={content_sections} />}
      {events.length > 0 && !loading && <EventCard content={data} />}
      {events?.length <= 0 && !loading && <Hero5 content={NoRecordsFoundData} />}
      {events.length && (
        <Pagination
          data={events}
          setData={setData}
          limit={PER_PAGE}
          totalRecords={events.length}
        />
      )}
    </>
  );
}
