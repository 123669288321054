import React, { useEffect } from "react";
import { Hero1 } from "luna-one";
import Stack from "../../Stack/Stack";
import ContentSection from "../../CustomComponents/Content-Section/ContentSection";
import "./SoftwareDownloads.css";

export default function SoftwareDownloads({ content }) {
  const downloadFile = (evt, fileURL) => {
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = "download";
    link.target = "_blank";
    link.click();
  }
  const openCity = (evt, cityName) => {
    let defaultActive = document.getElementById("defaultOpen");

    if (defaultActive.classList.contains("active")) {
      defaultActive.classList.remove("active");
    }

    var i, tabcontent, tablinks;

    tabcontent = document.getElementsByClassName("tabcontent");

    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByClassName("tablinks");

    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  };

  useEffect(() => {
    document.getElementById("tab_1").style.display = "block";
    let link = document.getElementById("defaultOpen");
    link.classList.add("active");
  }, []);
  
  const {
    image,
    name,
    sub_header,
    content_sections,
    software_downloads,
    created_at,
  } = content;

  const images = {
    desktop: {
      url: image?.source,
      altText: image?.alt,
    },
    tablet: {
      url: image?.source,
      altText: image?.alt,
    },
    mobile: {
      url: image?.source,
      altText: image?.alt,
    },
  };
  const theme1 = {
    backgroundColor: "#ffffff",
  };

  const authorData = {
    images,
    header: name,
    text: sub_header,
    theme: theme1,
  };

  return (
    <main>
      <Hero1 content={authorData} motion />
      <section className="home_tabs_container home_tabs_box_main">
        <div className="header_home_tabs">
          <h2>{name}</h2>
          <p dangerouslySetInnerHTML={{ __html: sub_header }}></p>
        </div>
        <ul className="home_tab">
          {software_downloads.map((software_download, index) => {
            return (
              <li key={index}>
                <button
                  className="tablinks"
                  onClick={(event) => openCity(event, `tab_${index + 1}`)}
                  id="defaultOpen"
                >
                  {software_download.software_category}
                </button>
              </li>
            );
          })}
        </ul>
        <div className="home_tab_section">
          {software_downloads?.map((software_download, index) => {
            return (
              <div key={index} id={`tab_${index + 1}`} className="tabcontent">
                <div className="tab_body">
                  <h1 className="text-center">{software_download.software_category}</h1>
                  <div className="row">
                    {software_download.downloads?.map((download, index) => {
                      return (
                        <div className="col-md-6 mx-auto">
                          <div class="home_tabs_box">
                            <h2 onClick={(event) => downloadFile(event, download.download_file_link)}>{download.download_title}</h2>
                            <p>{download.download_version}</p>
                            {download.other_links?.map((other_link, index) => {
                              return (
                                other_link.url ? <span onClick={(event) => downloadFile(event, other_link.url)}>{other_link.text}</span> : <p>{other_link.text}</p>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      {content_sections.length > 0 && <Stack publishedDate={created_at} content={content_sections}/>}
    </main>
  );
}