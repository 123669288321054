import React from "react";
import { Hero1 } from "luna-one";
import BlogCard from '../../CustomComponents/Blog_Card/BlogCard'
import ContentSocial from "../../CustomComponents/Content-Social/ContentSocial";
import Stack from "../../Stack/Stack";

export default function NewsDetail({content}) {

  const {
    sub_header,
    body,
    image,
    name,
    related_news_heading,
    related_news,
    content_sections,
    created_at
  } = content;

  const images = {
    desktop: {
      url: image?.source,
      altText: image?.alt,
    },
    tablet: {
      url: image?.source,
      altText: image?.alt,
    },
    mobile: {
      url: image?.source,
      altText: image?.alt,
    },
  };
  const theme1 = {
    backgroundColor: "#ffffff",
  };

  const HeroContent = {
    images,
    header: name,
    text: sub_header,
    theme: theme1,
  };

  return (
    <>
      <Hero1 content={HeroContent} motion />
      <ContentSocial publishedDate={created_at} content={body[0].value} />
      {content_sections.length > 0 && <Stack content={content_sections}/>}
      {related_news.length > 0 && <BlogCard header={related_news_heading} cards={related_news} />}
    </>
  );
}
