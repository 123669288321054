
const formatFeatTwoB = (
  featTwoBData = {},
  languageCode,
  languageEnabled,
  labels,
  fpoBox
) => {

  const {content, theme} = featTwoBData;

  const anchor =
  featTwoBData.anchor_toggle && featTwoBData.anchor_text ? featTwoBData.anchor_text : null;

  return {
    theme,
    content,
    type: "feat_2b",
    anchor,
  };
};

export default formatFeatTwoB;
