import { useState } from "react";

const useInnerPageNav = () => {
  // Responsible for setting and holding the data for the inner-page nav
  const initialState = { pageTitle: "", links: [] };
  const [innerPageNavData, setInnerPageNavData] = useState(initialState);
  return {
    innerPageNavData,
    setInnerPageNavData,
  };
};

export default useInnerPageNav;
