import { useState, useEffect } from "react";
import formatMenus from "../../utils/methods/formatMenus";
import formatMenuSiteData from "../../utils/methods/formatMenuSiteData";
// import logo

const useNavData = (
  mainNavResponse,
  setMainNavResponse,
  setNavURLs,
  applicationData,
  language
) => {
  // Fetches and formats the data for the navbar
  const [navData, setNavData] = useState(null);

  useEffect(() => {
    // format main and nav site data
    const formatNav = () => {
      const navOptions = formatMenus(mainNavResponse, language, true);
      const menuSiteData = formatMenuSiteData(applicationData, language);

      const newNavData = { navOptions, ...menuSiteData };
      setNavData(newNavData);

      // setMainNavResponse(null);
      // if (setNavURLs) {
      //   setNavURLs(null);
      // }
    };

    if (mainNavResponse) {
      formatNav();
    }
  }, [
    mainNavResponse,
    setMainNavResponse,
    setNavURLs,
    formatMenus,
    formatMenuSiteData,
    applicationData,
    language
  ]);

  return { navData, setNavData };
};

export default useNavData;
