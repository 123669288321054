import React from "react";
import emailIcon from "../../../assets/icon/email.svg";
import locationIcon from "../../../assets/icon/location.svg";
import phoneIcon from "../../../assets/icon/phone.svg";

export default function TabbedAddresses(props) {
  const {list,theme} = props.content

  return (
    <div
      className="ter-list-feat-main false ter-list-feat-main--modal-hidden map_add"
      style={{ zIndex: "auto",backgroundColor:theme }}
    >
      <div
        className="react-reveal"
        style={{
          animationFillMode: "both",
          animationDuration: "1000ms",
          animationDelay: "0ms",
          animationIterationCount: 1,
          opacity: 1,
          animationName: "react-reveal-290074148411893-1",
        }}
      ></div>
      <section className="ter-list-feat-six" style={{ zIndex: "auto" }}>
       {list.map((index,key)=>{
         return( <div
         key={key}
          className="react-reveal ter-list-feat-six__card"
          style={{
            animationFillMode: "both",
            animationDuration: "1000ms",
            animationDelay: "200ms",
            animationIterationCount: 1,
            opacity: 1,
            animationName: "react-reveal-290074148411893-1",
          }}
        >
                  <iframe
            src={index.embedded_map_code}
            allowFullScreen
            loading="lazy"
          />
          <article
            className="ter-icon-list-item">
            <div className="ter-icon-list-item__icon-wrapper">
            <img src={locationIcon} alt="play button" className="" />
            </div>
            <div className="ter-icon-list-item__content-wrapper">
            <div dangerouslySetInnerHTML={{ __html: index.address }} />
            </div>
          </article>

          <article
            className="ter-icon-list-item">
            <div className="ter-icon-list-item__icon-wrapper">
            <img src={phoneIcon} alt="play button" className="" />
            </div>
            <div className="ter-icon-list-item__content-wrapper">
              <p className="ter-icon-list-item__text">
              {index.phone}
              </p>
            </div>
          </article>


          <article
            className="ter-icon-list-item">
            <div className="ter-icon-list-item__icon-wrapper">
            <img src={emailIcon} alt="play button" className="" />
            </div>
            <div className="ter-icon-list-item__content-wrapper">
              <p className="ter-icon-list-item__text">
               {index.email}
              </p>
            </div>
          </article>

        </div>)
       })}
       
      </section>
    </div>
  );
}
