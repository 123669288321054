import React from "react";
import formatLink from "../../../utils/helpers/formatLink";

export default function Resource(props) {
  const { button, description, header } = props.content;

  return (
    <div
      className="react-reveal ter-list-feat-six__card"
      style={{
        animationFillMode: "both",
        animationDuration: "1000ms",
        animationDelay: "200ms",
        animationIterationCount: 1,
        opacity: 1,
        animationName: "react-reveal-290074148411893-1",
      }}
    >
      <h4>{header}</h4>
      <p>{description}</p>

      { button.text && 
        <a
          aria-current="page"
          className="ter-button ter-button--secondary--1 active"
          aria-label="link to CTA Two"
          href={formatLink(button.url)}
          style={{ textDecoration: "none" }}
        >
          {button.text}
        </a>
      }
    </div>
  );
}
