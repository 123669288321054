import React from "react";

export default function ContentSection(props) {
  const { header, subHeader, content, backgroundColor = "#FFF" } = props;

  return (
    <section
      style={{
        paddingTop: "10vh",
        paddingBottom: "10vh",
        backgroundColor: backgroundColor
      }}
      className="ter-filter-hero content_social"
      id="Filter-Hero Header"
    >
      {header && (
        <h2 className="ter-list-feat-main__header ter-feat__header">
          {header}
        </h2>
      )}
      {subHeader && (
        <p
          style={{
            textAlign: "center",
            marginBottom: "3vh",
            marginTop: "20px",
          }}
          className="ter-hero-one__text"
        >
          {subHeader}
        </p>
      )}
      <div className="content_inner blog_detail_page">
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </section>
  );
}
