import formatLink from "../../../helpers/formatLink";

const formatFeatSix = (
  featSixData = {},
  labels,
  fpoBox,
  fpoSquare
) => {
  const data = featSixData || {};
  const type = data.type;
  const theme = data.theme;
  const anchor =
    data.anchor_toggle && data.anchor_text ? data.anchor_text : null;

  const quote = {
    text: data.quote_body || null,
    source: data.quote_author || null,
    sourceTitle: data.quote_author_title || null,
  };
  const image = {
    src: data?.quote_image?.source || fpoSquare,
    alt: data?.quote_image?.alt || "Double quotes",
  };

  const cta = {
    url: data?.quote_cta?.url
      ? formatLink(data.quote_cta.url)
      : null,
    text: data?.quote_cta?.text,
  };

  const content = {
    quote,
    image,
    cta,
  };

  return {
    content,
    theme,
    type,
    anchor,
  };
};

export default formatFeatSix;
