import formatCTAs from "../formatCTAs";
import formatListFeatFourDumplings from "./formatListFeatFourCards";

const formatListFeatFour = (listFeatFourData, labels, fpoBox, fpoSquare) => {
  const data = listFeatFourData || {};
  const { header, subHeader } = data;
  const ctas = formatCTAs(data.ctas);

  const dumplings = formatListFeatFourDumplings(
    data?.list || [],
  );

  const content = { ctas, header, subHeader, dumplings, type: "list_feat_4" };
  const type = "listfeat_4";
  const theme = data.theme;

  const anchor = data.anchor_toggle && data.anchor_text ? data.anchor_text : null;

  return { content, type, theme, anchor };
};

export default formatListFeatFour;
