import formatLink from "../../../helpers/formatLink";

const formatFeatOne = (
  featOneData,
  fpoBox,
) => {
  const data = featOneData || {};
  const {type, theme, header, subHeader}  = data;
  const image = {
    src: data?.image?.source || fpoBox,
    alt: data?.image?.alt || "placeholder",
  };

  const text = data.text;

  const cta = {
    url:
      data.cta && data.cta.url
        ? formatLink(data?.cta.url)
        : null,
    text:
      data.cta && data.cta.text ? data.cta.text : null,
  };

  const anchor =
    data.anchor_toggle && data.anchor_text ? data.anchor_text : null;

  const content = {
    header,
    subHeader,
    text,
    image,
    cta,
  };

  return {
    content,
    type,
    theme,
    anchor,
  };
};

export default formatFeatOne;
