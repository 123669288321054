import { useState, useEffect } from "react";
import getNavURL from "../../utils/helpers/getNavURL";

const useNavResponse = (url, setUrls, language, region) => {
  // Fetches and formats the data for the navbar
  const [mainNavResponse, setMainNavResponse] = useState(null);

  useEffect(() => {
    // fetch main nav data

    const fetchData = () => {
      fetchMainNavData();

      if (setUrls && typeof setUrls === "function") {
        setUrls(null);
      }
    };

    const fetchMainNavData = async () => {
      let newUrl = getNavURL(language, region);
      console.log('new url', newUrl, url);
      const response = await fetch(newUrl);
      const data = await response.json();
      setMainNavResponse(data);
      console.log('fetching new data', data, url, language)
    };

    if (url) {
      fetchData();
    }
  }, [url, setUrls, language, region]);

  return {
    mainNavResponse,
    setMainNavResponse,
  };
};

export default useNavResponse;
