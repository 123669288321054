import { useState } from "react";

const useSearchForwarding = (history, language, languageEnabled) => {
  // Handles the history.push() for search forwarding
  const [searchString, setSearchString] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchString?.length) {
      const url = languageEnabled
        ? `/${language}/search?${searchString}`
        : `/search?${searchString}`;
      history.push(url);
      return;
    } else {
      return;
    }
  };

  const handleChange = (e) => {
    setSearchString(e.target.value);
  };

  return { handleSubmit, searchString, handleChange };
};

export default useSearchForwarding;
