import { useState, useEffect } from "react";
import backendURL from "../../utils/helpers/backendURL";

// import logo

const useNavURL = (language, region) => {
  // Fetches and formats the data for the navbar
  const [navURLs, setNavURLs] = useState(null);
  useEffect(() => {
   
    // set urls for main nav data
    // TODO - Create URL
    if (language) {
      const urlPrefix = backendURL(); 
      
      // determines if the language should be a part of the endpoint string
      const languagePrefix = language ? `/${language.id}` : "";
      const navURL = region ? `${urlPrefix}${languagePrefix}/api-v1/FetchMenuByName/${region.menu_name}`
      : `${urlPrefix}${languagePrefix}/api-v1/FetchMenuByName/main`;

      setNavURLs(
        navURL,
      );
      console.log('language inside nav url,', language, navURL);
    }
  }, [language, region]);

  return { navURLs, setNavURLs };
};

export default useNavURL;
