
import backendURL from "./backendURL";
import selectedLanguage from "./selectedLanguage";
import selectedRegion from "./selectedRegion";

const fetchNodeListings = async (type, page = 1, limit = 9, history, category = '', is_featured = '', search = '', tag = '') => {
    let filters = {
        type,
        page,
        limit,
    }

    if (category !== '') {
        filters.category = category;
    }

    if (is_featured !== '') {
        filters.is_featured = is_featured;
    }

    if (search !== '') {
        filters.search = search;
    }

    if (tag !== '' && tag !== null) {
        filters.tag = tag;
    }

    let region = selectedRegion();
    let newRegion = JSON.stringify({id: region.id, name: region.name});
    let headers = newRegion ? {"Region": newRegion} : {};

    if (is_featured === '') {
        const { type, limit, ...noA } = filters;
        history.push('?' + new URLSearchParams(noA), {from: 'landing_page'})
    }

    const response = await fetch(
      `${backendURL()}/${selectedLanguage()}/api-v1/GetNodeListing?${new URLSearchParams(filters)}`
    , {headers});

    const data = await response.json();
    return data;
  }
  
  export default fetchNodeListings;