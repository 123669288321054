import React from "react";
import useScrollTo from "../../hooks/useScrollTo";
import { Hero1, Feat1, Feat2A, Feat2B, Feat6, ListFeat,Feat3 } from "luna-one";
import { AccordionFold } from "terra-one";
import FilterHero from "../LunaComponents/Hero/FilterHero/Filterhero";
import BannerSection from "../CustomComponents/Banner-section/BannerSection";
import OverviewDetail from "../CustomComponents/Overview-detail/OverviewDetail";
import AllWebinarList from "../CustomComponents/All-webinar-list/AllWebinarList";
import ContactTrimble from "../CustomComponents/Contact-trimble/ContactTrimble";
import ParalexLink from "../CustomComponents/Paralex-link/ParalexLink";
import ParalexVideo from "../CustomComponents/Paralex-video/ParalexVideo";
import ThankYou from "../CustomComponents/Thank-you/ThankYou";
import ContactCards from "../CustomComponents/Contact-Cards/ContactCards";
import ResourcesCard from "../CustomComponents/Resources-Card/ResourcesCard";
import ContactInfo from "../CustomComponents/Contact-Info/ContactInfo";
import TabbedAddresses from "../CustomComponents/Tabbed-Addresses/TabbedAddresses";
import NewsSection from "../CustomComponents/News-Section/NewsSection";
import EventLanding from "../Pages/Landing/EventLanding";
import Newslanding from "../Pages/Landing/NewsLanding";
import { Element } from "react-scroll";
import ArticleDetail from "../Pages/Detail/ArticleDetail";
import BlogDetail from "../Pages/Detail/BlogDetail";
import CaseStudyDetail from "../Pages/Detail/CaseStudyDetail";
import NewsDetail from "../Pages/Detail/NewsDetail";
import ProductDetail from "../Pages/Detail/ProductDetail";
import WebinarDetail from "../Pages/Detail/WebinarDetail";
import ArticleLanding from "../Pages/Landing/ArticleLanding";
import BlogLanding from "../Pages/Landing/BlogLanding";
import CaseStudyLanding from "../Pages/Landing/CaseStudyLanding";
import ProductLanding from "../Pages/Landing/ProductLanding";
import WebinarLanding from "../Pages/Landing/WebinarLanding";
import PeopleCard from "../CustomComponents/People-Card/PeopleCard"
import ContentSection from "../CustomComponents/Content-Section/ContentSection";
import TabSection from "../CustomComponents/Tab-Section/TabSection";
import QouteCarousel from "../CustomComponents/Qoute-Carousel/QouteCarousel";
import IndustryLanding from "../Pages/Landing/IndustryLanding";
import FormSection from "../CustomComponents/FormSection/FormSection";
import Podcast from "../CustomComponents/Podcast/Podcast";
import OurBrands from '../CustomComponents/OurBrands/OurBrands';
import SoftwareDownloads from "../Pages/Detail/SoftwareDownloads";
const Stack = ({ content}) => {

  // Responsible for iterating over the content array passed down from Page and rendering corresponding organisms
  // Custom organisms can be added here by creating a new method and adding the new organism's type and method name to the methods array
  useScrollTo();
  const renderContent = () => {
    if (content.type === "blog_detail_page") {
      return <BlogDetail content={content} />;
    } else if (content.type === "blog_landing_page") {
      return <BlogLanding content={content} />;
    } else if (content.type === "events_landing_page") {
      return <EventLanding content={content} />;
    } else if (content.type === "article_landing_page") {
      return <ArticleLanding content={content} />;
    } else if (content.type === "article_detail_page") {
      return <ArticleDetail content={content} />;
    } else if (content.type === "case_studies_page") {
      return <CaseStudyLanding content={content} />;
    } else if (content.type === "case_studies_detail_page") {
      return <CaseStudyDetail content={content} />;
    } else if (content.type === "news_landing_page") {
      return <Newslanding content={content} />;
    } else if (content.type === "news_detail_page") {
      return <NewsDetail content={content} />;
    } else if (content.type === "product_landing_page") {
      return <ProductLanding content={content} />;
    } else if (content.type === "product_detail_page") {
      return <ProductDetail content={content} />;
    } else if (content.type === "webinar_landing_page") {
      return <WebinarLanding content={content} />;
    } else if (content.type === "webinar_detail_page") {
      return <WebinarDetail content={content} />;
    } else if (content.type === "industries") {
      return <IndustryLanding content={content} />;
    } else if (content.type === "software_downloads_page") {
      return <SoftwareDownloads content={content} />;
    } else {
      return content.map((organism, index) => {
        if (organism && organism.type && methods[organism.type]) {
          return methods[organism.type](organism, index);
        } else {
          return <div key={index}></div>;
        }
      });
    }
  };

  const renderFeatOne = ({ content, theme, type, index, anchor }) => {
    return (
      <Element key={`${index}-${type}`} name={anchor}>
        <Feat1 content={content} motion theme={theme} />
      </Element>
    );
  };

  const renderFeatTwoA = ({ content, theme, type, index, anchor }) => {
    return (
      <Element key={`${index}-${type}`} name={anchor}>
        <Feat2A
          image={content.image}
          content={content}
          header={content.header}
          text={content.text}
          theme={theme}
          motion
        >
          {renderAccordionFolds(content.children)}
        </Feat2A>
      </Element>
    );
  };

  const renderAccordionFolds = (data) => {
    // Feat2A renders AccordionFolds as children
    return data?.map((fold, index) => {
      return (
        <AccordionFold key={index} header={fold.header || "header"}>
          {fold.text || "text"}
        </AccordionFold>
      );
    });
  };

  const renderFeatTwoB = ({ content, theme, type, index, anchor }) => {
    return (
      <Element key={`${index}-${type}`} name={anchor}>
        <Feat2B
          content={content}
          header={content.header}
          text={content.text}
          theme={theme}
          motion
        />
      </Element>
    );
  };

  const renderFeatSix = ({ content, theme, type, index, anchor }) => {
    return (
      <Element key={`${index}-${type}`} name={anchor}>
        <Feat6 content={content} motion theme={theme} />
      </Element>
    );
  };
  const rednerFeatThree = ({ content, theme, type, index, anchor }) => {
    return (
      <Element key={`${index}-${type}`} name={anchor}>
        <Feat3 content={content} motion theme={theme} />
      </Element>
    );
  };

  const renderFilterHero = (content, index) => {
    return <FilterHero content={content} />;
  };

  const renderHeroOne = ({ content, theme, type, index }) => {
    return (
      <Hero1 key={`${index}-${type}`} content={content} motion theme={theme} />
    );
  };

  const renderListFeatThree = ({ content, theme, type, pid, index, anchor }) => {
    return (
      <Element key={`${pid}-${type}`} name={anchor}>
        <ListFeat type="list_feat_3" content={content} theme={theme} motion />
      </Element>
    );
  };

  const renderListFeatFour = ({ content, theme, type, index, anchor }) => {
    return (
      <Element key={`${index}-${type}`} name={anchor}>
        <ListFeat type={type} content={content} theme={theme} motion />
      </Element>
    );
  };

  const renderListFeatFive = ({ content, theme, type, index, anchor }) => {
    return (
      <Element key={`${index}-${type}`} name={anchor}>
        <ListFeat type={type} content={content} theme={theme} motion />
      </Element>
    );
  };

  //Custom components
  const renderBannerSection = (content, index) => {
    return <BannerSection content={content} />;
  };

  const renderOverviewDetail = (content, index) => {
    return <OverviewDetail content={content} />;
  };

  const renderContactTrimble = (content, index) => {
    return <ContactTrimble content={content} />;
  };

  const renderAllWebinarList = (content, index) => {
    return <AllWebinarList content={content} />;
  };

  const renderParalexLink = (content, index) => {
    return <ParalexLink content={content} />;
  };

  const renderParalexVideo = (content, index) => {
    return <ParalexVideo content={content} />;
  };

  const renderThankYou = (content, index) => {
    return <ThankYou content={content} />;
  };

  const renderContactCards = (content, index) => {
    return <ContactCards content={content} />;
  };

  const renderResourcesCard = (content, index) => {
    return <ResourcesCard content={content} />;
  };

  const renderContactInfo = (content, index) => {
    return <ContactInfo content={content} />;
  };

  const renderTabbedAddresses = (content, index) => {
    return <TabbedAddresses content={content} />;
  };
  const renderNewsSection = (content, index) => {
    return <NewsSection content={content} />;
  };
  const renderPeopleCard = (content, index) => {
    return <PeopleCard content={content} />;
  };

  const renderTabsSection = (content, index) => {
    return <TabSection content={content} />;
  };

  
  const renderQuoteCarousel = (content, index) => {
    return <QouteCarousel content={content} />;
  };

  const renderHtmlSection = (content, index) => {
    const { header, subHeader, theme } = content;
    return (
      <ContentSection
        header={header}
        subHeader={subHeader}
        content={content.content}
        backgroundColor={theme.backgroundColor}
      />
    );
  };

  const renderFormSection = (content, index) => {
    return <FormSection content={content} backgroundColor={content.theme?.backgroundColor}/>;
  };

  const renderPodcastSection = (content, index) => {
    return <Podcast content={content} />;
  };

  const renderOurBrands = (content, index) => {
    return <OurBrands content={content}/>
  }
  const methods = {
    // keys are references to the organism type from JSON, values are the corresponding methods that return the specific organism
    FilterHero: renderFilterHero,
    hero_1: renderHeroOne,
    feat_1: renderFeatOne,
    feat_2a: renderFeatTwoA,
    feat_2b: renderFeatTwoB,
    feat_3:rednerFeatThree,
    feat_6: renderFeatSix,
    f6_quotes_with_carousel: renderQuoteCarousel,
    listfeat_3: renderListFeatThree,
    listfeat_4: renderListFeatFour,
    listfeat_5: renderListFeatFive,
    banner_section: renderBannerSection,
    news_section: renderNewsSection,
    resource_section: renderResourcesCard,
    parallex_video_section: renderParalexVideo,
    map_addresses_section: renderTabbedAddresses,
    contact_cards_section: renderContactCards,
    parallex_cards_section: renderParalexLink,
    overview_section: renderOverviewDetail,
    tabs_section: renderTabsSection,
    thank_you_section: renderThankYou,
    contact_tabs: renderContactTrimble,
    contact_info_section: renderContactInfo,
    contact_cards_image_section:renderPeopleCard,
    html_section: renderHtmlSection,
    webinar_section: renderAllWebinarList,
    form_section: renderFormSection,
    podcast_section: renderPodcastSection,
    our_brands : renderOurBrands
  }

  return <>{renderContent()}</>;
};

export default Stack;
