import { useState } from "react";

const useMetadata = () => {
  // Sets the metadata that is passed into React Helmet
  // TODO update metadata initial state
  const [metadata, setMetadata] = useState({});

  return {
    metadata,
    setMetadata,
  };
};

export default useMetadata;
