const formatListFeatSevenCards = (
  listFeatSevenData,
  languageCode,
  languageEnabled,
  fpoBox,
  fpoSquare
) => {
  const data = listFeatSevenData || {};
  const keys = Object.keys(data);

  return keys.map((key) => {
    const card = {
      header: data[key].header,
      text: data[key].description,
      thumbnail: {
        url: data[key]?.image?.source || fpoSquare,
        altText: data[key]?.image?.alt || "card image",
      },
    };

    if (data[key]?.video?.code) {
      const { video } = data[key];
      card.video = {
        url:
          video.source && video.source !== "vidyard"
            ? `https://www.youtube.com/watch?v=${video.code}`
            : null,
        uuid: video.source && video.source === "vidyard" ? video.code : null,
      };

      card.vidyard = video.source && video.source === "vidyard" ? true : false;
    }

    if (card.video) {
      card.thumbnail = {
        url: data[key]?.video?.poster?.source || fpoBox,
        altText: data[key]?.video?.poster?.alt || "placeholder image",
      };
    }

    if (data[key]?.big_image?.source) {
      card.image = {
        url: data[key].big_image.source,
        altText: data[key].big_image.alt,
      };

      card.thumbnail = null;
    }

    return card;
  });
};

export default formatListFeatSevenCards;
