import formatLink from "../helpers/formatLink";
import selectedRegion from "../helpers/selectedRegion";
import navLogo from "../../assets/images/trimble-logo.svg";

const formatMenuSiteData = (applicationData, language) => {
  const { contact_us_url, support_url, site_logo, logo_caption } = selectedRegion();

  const rightLinks = [
    {
      url: support_url ? formatLink(support_url) : "/",
      text: "Support",
    },
  ];

  const navCTA = {
    className: "ter-button--primary--1",
    link: {
      url: contact_us_url ? formatLink(contact_us_url) : "/",
      text: applicationData?.contactUsText || "Contact Us",
    },
  };

  const logo = {
    url: site_logo || navLogo,
    altText: "Trimble Logo",
    link: {
        url: `/${language.id}`,
    },
  };

  return {
    logo,
    rightLinks,
    navCTA,
    divisionName: logo_caption || "Utilities & Public Administration",
    searchPlaceholder: "Search Here ",
  };
};

export default formatMenuSiteData;
