import React from "react";
import playIcon from "../../../assets/icon/play.svg";

export default function OverviewDetail({ content }) {
  const {
    description,
    header,
    theme,
    video,
    background_image,
    enable_parallex_effect,
  } = content;
  
  const isUploadedVideo = video.source.includes("trimble");

  return (
    <div id="root">
      <section
        className={`luna-feat-five ${enable_parallex_effect ? "parallax" : ""}`}
        style={{
          zIndex: "auto",
          backgroundImage: "url('" + background_image?.source + "')",
          backgroundColor: theme.backgroundColor,
          color: theme.color,
        }}
      >
        <div
          className="react-reveal luna-feat-five__text-content"
          style={{
            opacity: 1,
          }}
        >
          <h2
            className="luna-feat-five__header ter-feat__header"
            style={{ color: "rgb(54, 53, 69)" }}
          >
            {header}
          </h2>
          <div
            className="luna-feat-five__sub-header-holder ter-feat__sub-header-container"
            style={{ color: "rgb(54, 53, 69)" }}
          >
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
          </div>
        </div>

        <section className="">
          {isUploadedVideo ? (
            <video
              // poster={video.thumbnail}
              autoPlay={false}
              controls={true}
              controlsList={"nodownload"}
              mute={true}
              width={560}
              height={315}
              src={`${video.source}#t=0.1`}
              frameBorder={0}
              allowFullScreen
            />
          ) : (
            <iframe src={video.source} width={560} height={315} />
          )}
        </section>
      </section>
    </div>
  );
}
