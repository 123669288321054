import { useState, useEffect } from "react";
import backendURL from "../../utils/helpers/backendURL";

const useFooterURL = (language, region) => {
  // Responsible for fetching and formatting the footer data
  const [footerURL, setFooterURL] = useState(null);

  useEffect(() => {
    // set url
    const determineURL = () => {
       const languagePrefix = language ? `/${language.id}` : "en";
      const footerURL = region ? `${backendURL()}${languagePrefix}/api-v1/FetchFooterByMachineName/${region.footer_name}`
      : `${backendURL()}${languagePrefix}/api-v1/FetchFooterByMachineName/footer`;

      setFooterURL(footerURL);
    };

    if (language && region) {
      determineURL();
    }
  }, [language, region]);

  return { footerURL, setFooterURL };
};

export default useFooterURL;
