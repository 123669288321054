import React from "react";
import "./ThankYou.scss";
import like1 from "../../../assets/images/thankyyou.png";
export default function ThankYou({content}) {
  const {header, description} = content;

  return (
    <div id="root">
      <div
        className="ter-list-feat-main false ter-list-feat-main--modal-hidden"
        style={{ zIndex: "auto", backgroundColor: "rgb(249, 249, 251)" }}
      >
        <div
          className="react-reveal"
          style={{
            animationFillMode: "both",
            animationDuration: "0ms",
            animationDelay: "0ms",
            animationIterationCount: 1,
            opacity: 1,
            animationName: "react-reveal-466845260446205-1",
          }}
        ></div>
        <section className="ter-list-feat-seven">
          <div
            className="react-reveal ter-variable-card ter-variable-card--not-deployed"
            style={{
              backgroundColor: "rgb(249, 249, 251)",
              animationFillMode: "both",
              animationDuration: "0ms",
              animationDelay: "0ms",
              animationIterationCount: 1,
              opacity: 1,
              animationName: "react-reveal-466845260446205-1",
              width: "100%",
            }}
          >
            <div
              className="luna-video-feat__thumbnail-container thank-you-section"
              tabIndex
              style={{ textAlign: "center" }}
            >
              <img src={like1} alt="play button" />
            </div>
            <div
              className="ter-variable-card__body"
              style={{
                alignItems: "center",
              }}
            >
              <h2
                className="ter-list-feat-main__header ter-feat__header header_Data"
                style={{
              
                  paddingBottom: "20px",
                  color: "#005f9e",
                }}
              >
                {header}
              </h2>
              { description && <p 
                dangerouslySetInnerHTML={{ __html: description }} 
                className="paragraph ter-variable-card__heade justify-center"
                style={{ color: "rgb(54, 53, 69)" }} 
                ></p>
              }
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
