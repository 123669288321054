import backendURL from "./backendURL";

const getNavURL = (language, region) => {
  const urlPrefix = backendURL();

  // determines if the language should be a part of the endpoint string
  const languagePrefix = language ? `/${language.id}` : "";
  const navURL = region
    ? `${urlPrefix}${languagePrefix}/api-v1/FetchMenuByName/${region.menu_name}`
    : `${urlPrefix}${languagePrefix}/api-v1/FetchMenuByName/main`;

  return navURL;
};

export default getNavURL;
