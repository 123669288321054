import React, { useState, useEffect } from "react";
import { NavBar } from "luna-one";
import { withRouter } from "react-router-dom";
import "./NavBarHandler.scss";
import regionLanguageMapping from "../../utils/helpers/regionLanguageMapping";

const NavBarHandler = ({
  data,
  innerPageNavData,
  history,
  regionData,
  applicationData,
  languages,
  location,
  language,
  setLanguage,
  navCTAOverride,
}) => {
  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
  const { region, regions, setRegion } = regionData;
  const { navOptions, rightLinks, navCTA, logo, divisionName } = data;

  const searchSubmit = (string) => {
    if (string && string.length && history) {
      const url = `/${language}/search?${string}`;
      history.push(url);
      return;
    }
  };
  useEffect(() => {
    setTimeout(() => {
      const megaMenus = document.getElementsByClassName(
        "ter-mobile-mega-menu-category"
      );

      const supportLink = document.querySelector(".ter-top-mobile-nav__link");
      if (supportLink) {
        supportLink.addEventListener("click", function() {
          document
            .querySelector(".ter-navbar-top__right--mobile-hamburger")
            .click();
        });
      }

      const logo = document.querySelector(".ter-navbar-top__logo-link");
      if (logo) {
        logo.addEventListener("click", function() {
          document
            .querySelector(".ter-navbar-top__right--mobile-hamburger--is-open")
            ?.click();
        });
      }

      for (let menu of megaMenus) {
        menu.addEventListener("click", function() {
          for (let child of menu.childNodes) {
            if (child.className === "ter-mobile-mega-menu-category__submenu ") {
              if (child.style.maxHeight === "1000px") {
                child.style.maxHeight = "0px";
                child.style.transition = "none";
              } else {
                child.style.transition = "none";
                child.style.maxHeight = "1000px";
              }
              break;
            }
          }
        });
      }
    }, 1000);
  }, [region]);

  const toggleLanguageSelectorDeployed = (bool) => {
    if (bool === false) {
      setLanguageSelectorOpen(false);
    } else if (bool) {
      setLanguageSelectorOpen(bool);
    } else {
      setLanguageSelectorOpen(!languageSelectorOpen);
    }
  };

  const getFormattedLanguages = (regionLanguage) => {
    return languages
      ?.filter((language) => language.id === regionLanguage)
      .map((language) => {
        return `${language?.id?.toUpperCase()}: ${language.name}`;
      });
  };

  const getRegions = () => {
    return regions
      //?.filter((region) => !["Poland"].includes(region.name))
      ?.map((region) => {
        const formattedLanguages = getFormattedLanguages(
          regionLanguageMapping[region.name]
        );

        return {
          region: region.name,
          languages: formattedLanguages,
        };
      });
  };

  const getLanguageSelectorData = () => {
    return {
      labels: {
        currentRegion:
          applicationData?.currentLanguageLabel || "Current Region",
        languageOptionsLabel:
          applicationData?.languageOptionsLabel || "Language Options",
      },
      regions: getRegions(),
      activeRegion: { region: region?.name },
      companyDivision: applicationData?.siteTitle,
    };
  };

  const setNewLanguage = (response) => {
    // console.log(response);
    const selectedRegion = regions.find(
      (item) => item.name === response.region
    );

    if (selectedRegion) {
      setRegion(selectedRegion);
    }

    const langCode = response?.language
      ?.substr(0, response?.language?.indexOf(":"))
      ?.toLowerCase();

    const selectedLanguage = languages.find((item) => item.id === langCode);

    if (language) {
      localStorage.setItem("language", JSON.stringify(selectedLanguage));
      setLanguage(selectedLanguage);
      changeURL(selectedLanguage);

      if (selectedRegion.name !== region.name) {
        history.push(`/${selectedLanguage.id}`);
      }
    }

    setLanguageSelectorOpen(false);
  };

  function changeURL(language) {
    const { pathname } = location;
    const splitPath = pathname.split("/");
    splitPath[1] = language.id;
    const newPath = splitPath.join("/");
    history.push(newPath);
  }

  return (
    <NavBar
      regionLanguageDeployed={languageSelectorOpen}
      toggleRegionLanguageDeployed={toggleLanguageSelectorDeployed}
      rightLinks={rightLinks}
      divisionName={divisionName}
      navOptions={navOptions}
      logo={logo}
      navCTA={navCTA}
      // searchSubmit={searchSubmit}
      currentLanguage={{
        region: region?.name,
        language: language,
      }}
      language={language}
      utilityNav={innerPageNavData}
      languageSelectorData={getLanguageSelectorData()}
      setLanguage={setNewLanguage}
      regionEnabled={true}
    />
  );
};

export default withRouter(NavBarHandler);
