import React from "react";
import emailIco from "../../../assets/icon/email.svg";
import phoneIco from "../../../assets/icon/phone.svg";
import "./people_card.css";

export default function PeopleCard(props) {
  const { contact_cards, header } = props.content;
  return (
    <section className="peopleCard_container">
      <h2>{header}</h2>
      <div className="peopleCard_inner">
        {contact_cards.map((index, key) => {
          return (
            <div key={key} className="people_card">
              <div
                style={{ width: "288px", height: "250px", overflow: "hidden" }}
              >
                <img
                  src={index.image.source}
                  alt={index.image.alt}
                  className="imgResponsive"
                />
              </div>
              <div className="people_card_body">
                <h4>{index.header}</h4>
                <p>{index.sub_header}</p>
                <ul>
                  {index.phone &&
                    <li>
                      {" "}
                      <img src={phoneIco} alt="phone icon" /> {index.phone}{" "}
                    </li>
                  }
                  {index.email &&
                    <li>
                      {" "}
                      <img src={emailIco} alt="phone icon" /> {index.email}{" "}
                    </li>
                  }
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}
