import { useState, useEffect } from "react";
import formatFooterData from "../../utils/methods/formatFooterData";

const useFooterData = (
  language,
  footerResponse,
  setFooterURL,
  setFooterResponse
) => {
  // Responsible for fetching and formatting the footer data
  const [footerData, setFooterData] = useState(null);

  useEffect(() => {
    const formatData = () => {
      const languageCode = language.id; 
      const formattedData = formatFooterData(footerResponse, languageCode);

      setFooterData(formattedData);
      setFooterResponse(null);
      setFooterURL(null);
    };

    if (footerResponse) {
      formatData();
    }
  }, [language, footerResponse, setFooterURL, setFooterResponse]);

  return { footerData, setFooterData };
};

export default useFooterData;
