import React from "react";

import { Hero1 } from "luna-one";
import Stack from "../../Stack/Stack";
import BlogCard from "../../CustomComponents/Blog_Card/BlogCard.js";
import EventsList from "../../CustomComponents/EventsList/EventsList";

export default function IndustryLanding({ content }) {

  const {
    categories,
    news_section,
    blog_section,
    content_sections,
    events_section
  } = content;

  const heroOne = content_sections.filter(
    (section) => section.type === "hero_1"
  )[0];
  
  const contentSections = content_sections.filter(
    (section) => section.type !== "hero_1"
  );

  return (
    <>
      {heroOne && <Hero1 content={heroOne.content} />}
      {contentSections.length > 0 && <Stack content={contentSections} />}
      {news_section.related_news?.length > 0 && (
        <BlogCard
          header={news_section.header}
          subHeader={news_section.sub_header}
          selectedCategory={categories[0]}
          cards={news_section.related_news}
        />
      )}
      {blog_section.recent_blog?.length > 0 && (
        <BlogCard
          header={blog_section.header}
          subHeader={blog_section.sub_header}
          selectedCategory={categories[0]}
          cards={blog_section.recent_blog}
          backgroundColor="#FFF"
        />
      )}
      {events_section && !events_section.hide_events_section && (
        <EventsList
          header={events_section.header}
          subHeader={events_section.sub_header}
          selectedCategory={events_section.calendar_id}
          backgroundColor="#FFF"
        />
      )}
    </>
  );
}
