const formatFeatSeven = (featSevenData = {}, languageCode, languageEnabled) => {
  const data = featSevenData || {};
  const header = data.intro_header;
  const subHeader = data.intro_description;
  const tabs = formatTabsForFeatSeven(data.list, languageCode, languageEnabled);
  const anchor =
    data.anchor_toggle && data.anchor_text ? data.anchor_text : null;

  const content = {
    header,
    subHeader,
    tabs,
  };
  return {
    content,
    type: "feat_7",
    theme: data.theme,
    anchor,
  };
  // TODO Write this when JSON is returned from application
};

export const formatTabsForFeatSeven = (
  data = [],
  languageCode,
  languageEnabled
) => {
  const listFeatThreeLabel = {
    label: "s_tab_section_cards",
    method: formatTabbedListFeatThree,
  };
  const listFeatFourLabel = {
    label: "s_tab_section_icon_dumplings",
    method: formatTabbedListFeatFour,
  };
  const listFeatFiveLabel = {
    label: "s_tab_section_icon_items",
    method: formatTabbedListFeatFive,
  };
  const types = [listFeatThreeLabel, listFeatFourLabel, listFeatFiveLabel];
  return data.map((tab) => {
    const method = types.reduce(
      (result, resultLabel) => {
        if (tab.type === resultLabel.label) {
          result = resultLabel.method;
        }

        return result;
      },
      () => {}
    );

    return method(tab, languageCode, languageEnabled);
  });
};

export const formatTabbedListFeatThree = (
  data,
  languageCode,
  languageEnabled
) => {
  const type = "list_feat_3";
  const header = data.header;
  const subHeader = data.description;
  const ctas = {};
  const cards = data?.list?.map((card) => {
    return {
      header: card.header,
      image: {
        url: card?.image?.source,
        altText: card?.image?.alt,
      },
      text: card.description,
      button: {
        url: languageEnabled
          ? `/${languageCode}${card?.cta?.url}`
          : card?.cta?.url,
        text: card?.cta?.text,
      },
    };
  });

  return {
    type,
    header,
    subHeader,
    ctas,
    cards,
  };
};

export const formatTabbedListFeatFour = (
  data,
  languageCode,
  languageEnabled
) => {
  // leaving the languageCode/languageEnabled arguments in the event that we start to see JSON for the CTAs
  const type = "list_feat_4";
  const header = data.header;
  const subHeader = data.description;
  const ctas = {};

  const dumplings = data?.list?.map((dumpling) => {
    return {
      header: dumpling.text,
      link: null,
      size: "small",
      icon: { type: "enclosed-check-dark-48px", size: "48px" },
    };
  });

  return { type, header, subHeader, ctas, dumplings };
};

export const formatTabbedListFeatFive = (
  data,
  languageCode,
  languageEnabled
) => {
  // leaving the languageCode/languageEnabled arguments in the event that we start to see JSON for the CTAs
  const type = "list_feat_5";
  const header = data.header;
  const subHeader = data.description;
  const ctas = {};
  const iconListItems = data?.list?.map((icon) => {
    return {
      header: icon.header,
      text: icon.text,
    };
  });

  return { type, header, subHeader, ctas, iconListItems };
};

export default formatFeatSeven;
