// TODO add real backend url and env variables

const getCalendars = () => {
  return {
    Julkishallinto: "trimble.com_bu4ig1pmik3llmcdfk094s25f4@group.calendar.google.com",
    Utilities: "trimble.com_6bgukvntv7do0drfcm8vae2abk@group.calendar.google.com",
    Electric: "c_tjj1326bo0s9dfakp8o1hnffuk@group.calendar.google.com",
  };
};

export default getCalendars;
