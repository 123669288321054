const apiKey = "AIzaSyDxJLyraShZiHZvWK7yQsYFxXGVGrKLZMk";

const getEventByIndustry = async (selectedIndustry) => {
  var response = await window.gapi.client.request({
    path: `https://www.googleapis.com/calendar/v3/calendars/${selectedIndustry}/events?key=${apiKey}`,
  });
  
  let events = response.result.items;
  if (events.length > 0) {
    console.log(events);
    events = events.filter(event => {
      if (!event.end) {
          return false;
      }
      let date = new Date(event.end.date || event.end.dateTime);
      let evaluation = date >= new Date()
      console.log('date is', date, evaluation);
      return evaluation;
  }).sort((a, b) => new Date(a.start.date || a.start.dateTime) - new Date(b.start.date || b.start.dateTime));
    // events = events
    //   .filter(event => new Date(event.end.date || event.end.dateTime) >= new Date())
    //   .sort((a, b) => new Date(a.start.date || a.start.dateTime) - new Date(b.start.date || b.start.dateTime));
  }

  return events;
};

export default getEventByIndustry;
