import formatLink from "../../../helpers/formatLink";

const formatListFeatThreeCards = (
  listFeatThreeCards,
  fpoSquare,
) => {
  const cards = listFeatThreeCards || {};

  return cards.map((card) => {
    var transformedCard = {
      header: card?.header,
      text: card?.description,
      image: {
        url: card?.image?.source || fpoSquare,
        altText: card?.image?.alt,
      }
    }
    
    if (card.cta && card.cta.url !== "" && card.cta.url !== null) {
      transformedCard.link = {
        url: card?.cta?.url
          ? formatLink(card.cta.url)
          : null,
        text: card?.cta?.text,
      }
    }

    return transformedCard;
  });

};

export default formatListFeatThreeCards;
