import React from "react";
import { Hero1 } from "luna-one";
import ContentSocial from "../../CustomComponents/Content-Social/ContentSocial";
import AboutAuthor from "../../CustomComponents/About-Author/AboutAuthor";
import Stack from "../../Stack/Stack";
import Article from "../../CustomComponents/Article/Article";
import NavigationLinks from "../../CustomComponents/NavigationLinks/NavigationLinks";

export default function BlogDetail({ content }) {
  console.log(content);
  const {
    sub_header,
    about_author,
    body,
    image,
    name,
    related_posts,
    related_products,
    tags,
    applicationData,
    content_sections,
    created_at,
    nagivation_links,
    hide_author_info
  } = content;

  const images = {
    desktop: {
      url: image?.source,
      altText: image?.alt,
    },
    tablet: {
      url: image?.source,
      altText: image?.alt,
    },
    mobile: {
      url: image?.source,
      altText: image?.alt,
    },
  };

  const heroContent = {
    images,
    header: name,
    text: sub_header,
  };
  
  return (
    <main>
      <Hero1 content={heroContent} motion />
      <ContentSocial
        content={body[0].value}
        tags={tags}
        publishedDate={created_at}
        navigationLinks={nagivation_links}
      />
      {content_sections.length > 0 && <Stack content={content_sections} />}
      {nagivation_links && <NavigationLinks navigationLinks={nagivation_links}/>}
      {related_posts.length > 0 && (
        <Article
          learnMoreText={applicationData.learnMoreText}
          content={related_posts}
          header={applicationData?.otherBlogHeading}
        />
      )}
      {about_author && !hide_author_info && <AboutAuthor content={about_author} />}
      {related_products.length > 0 && (
        <Article
          content={related_posts}
          learnMoreText={applicationData.learnMoreText}
          header={applicationData?.relatedProductsHeading}
        />
      )}
    </main>
  );
}
