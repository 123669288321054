import { useEffect, useState } from "react";
import backendURL from "../../utils/helpers/backendURL";
import { useHistory } from "react-router-dom";
import regionLanguageMapping from "../../utils/helpers/regionLanguageMapping";
import useRegion from "../region/useRegions";
import { userLocation } from "../../utils/helpers/userLocation";

const usePageURL = (
  location,
  language,
  languages,
  region,
  setRegion,
  regions,
  routes,
  setStatus,
  languageEnabled,
  setLanguage
) => {
  const [url, setURL] = useState(null);
  let history = useHistory();

  let newLanguages = ["en-us", "en-eu", "en-au", "de-de", "fi-fi", "sv-se", "no-no", "pl-pl"];
  let enRegions = ["us", "eu", "au"];
  let allRegions = ["us", "eu", "au", "de", "fi", "se", "no", "pl"];
  let languageValues =  ["de", "fi", "en", "sv", "no", "pl"];

  const getLanguageCode = (apparentLanguageCode, regionCode) => {
    // console.log(apparentLanguageCode, regionCode);
    if (apparentLanguageCode == "") {
      // console.log('region is', regionCode);
      return regionCode;
    }
    if (apparentLanguageCode == "en") {
      // console.log('hitting here', apparentLanguageCode, regionCode);
      if(!regionCode || !enRegions.includes(regionCode)) {
        return "us";
      }
      return regionCode;
    } else {
      const correctRegion = Object.keys(regionLanguageMapping).find(
        (key) => regionLanguageMapping[key] === apparentLanguageCode
      );
      // console.log('correct', correctRegion, apparentLanguageCode, regions);
      let region = regions.filter(region => region.name == correctRegion);
      return region[0]?.region_code;
    }
  }

  const getApparentLanguageCode = (apparentLanguageCode, regionCode) => {
    // console.log(apparentLanguageCode, regionCode);
    if (apparentLanguageCode == "") {
      // console.log('hitting here', apparentLanguageCode, regionCode);
      if (enRegions.includes(regionCode)) {
        return "en";
      } else {
        return language.id;
      }
    }
    return apparentLanguageCode;
  }

  useEffect(() => {
    // SET THE URL FOR FETCHING
    const checkForForwarding = () => {
      const { pathname } = location;
      const splitPath = pathname.split("/");
  
      const apparentLanguageCode = splitPath[1];
      const languagesClone = [...languages];

      const correctRegion = Object.keys(regionLanguageMapping).find(
        (key) => regionLanguageMapping[key] === apparentLanguageCode
      );
      // console.log('split path is', splitPath);
      if (!newLanguages.includes(apparentLanguageCode) && apparentLanguageCode !== "en" && correctRegion && correctRegion !== region.name) {
        // console.log('hitting 1');
        const selectedRegion = regions?.filter(
          (region) => region.name === correctRegion
        )[0];
        // console.log("hit 1" + selectedRegion.name);
        setRegion(selectedRegion);
      }
      if ((splitPath.length <= 2  || splitPath[2] == '' || splitPath[0] == "") && !newLanguages.includes(apparentLanguageCode)) {
        // console.log('hitting 2');
        if (!region) {
          userLocation(regions).then((region) => {
            // console.log('region is', region.region_code);
            setRegion(region);
            const newPath = pathname.replace(apparentLanguageCode, `${getApparentLanguageCode(apparentLanguageCode, region.region_code)}-${getLanguageCode(apparentLanguageCode, region.region_code)}`);
            // console.log('new path is', newPath);
            history.push(newPath);
          });
        
        } else {
          let newPath = '';
          // console.log('region 1 is', getLanguageCode(apparentLanguageCode, region.region_code));
          newPath = pathname.replace(apparentLanguageCode, `${getApparentLanguageCode(apparentLanguageCode, region.region_code)}-${getLanguageCode(apparentLanguageCode, region.region_code)}`);
          // console.log('new path is', newPath, newPath.length);
          // if (newPath.length <= 6) {
            history.push(newPath);
          // }
        }
      } else if (apparentLanguageCode === "") {
        const newPath = `/${language.id}${pathname}`;
        history.push(newPath);
      } else if (newLanguages.includes(apparentLanguageCode)) {
        // console.log('hit 2');
        let value = apparentLanguageCode.split("-");

        let newRegion = allRegions.includes(value[1]) ? value[1] : 'us';
        // console.log('new region selected is', newRegion);
        let newLanguage = value[0];
        if (newLanguage !== language.id) {
          // console.log('new lang selected is', newLanguage);
          const selectedLanguage = languagesClone.filter(
            (lang) => lang.id === newLanguage
          )[0];
          setLanguage(selectedLanguage);
          localStorage.setItem("language", JSON.stringify(selectedLanguage));
        }
      
        if (newRegion !== region.region_code) {
          const selectedRegion = regions?.filter(
            (region) => region.region_code === newRegion
          )[0];
          setRegion(selectedRegion);
          // console.log("hit 3" + selectedRegion.name);
        }
        getURL();
      }

      //if not matching current language
      else if (apparentLanguageCode === language.id) {
        // language code matches, continue to generate the endpoint URL
        getURL();
      } else if (apparentLanguageCode !== language.id && languageValues.includes(apparentLanguageCode)) {
        // console.log('hit 4');
        const selectedLanguage = languagesClone.filter(
          (lang) => lang.id === apparentLanguageCode
        )[0];
        if (selectedLanguage) {
          setLanguage(selectedLanguage);
          // console.log(language);
          localStorage.setItem("language", JSON.stringify(selectedLanguage));
        }
      } else {
        // console.log('hit');
        const newPath = `/${language.id}`;
        history.push(newPath);
      }
    };

    const getURL = () => {
      const { pathname } = location;
      // const apparentLanguageCode = splitPath[1];
      let path;
      // if language is in path, we want to know. We check for substring(1, 3) against language bc substring(1, 3) will be "en", "es", "ch", etc
      if (newLanguages.includes(pathname.substring(1, 6))) {
        path = pathname.substring(7);
        // if path is /page-1 it will now be page-1
      } else if (pathname.substring(1, 3) === language.id) {
        path = pathname.substring(4);
      } else {
        path = pathname.length > 1 ? pathname.substring(1) : pathname;
      }

      if (path.indexOf("/") > -1) {
        path = path.replaceAll("/", "$");
      }

      if (
        pathname == null ||
        pathname === "" ||
        pathname === "/" ||
        pathname === `/${language.id}/` ||
        pathname === `/${language.id}` ||
        (newLanguages.includes(pathname.split("/")[1]) &&
          ["", undefined].includes(pathname.split("/")[2]))
      ) {
        var homePath =
          region?.home_url && region?.home_url !== ""
            ? region.home_url
            : "/home";

        setURL(
          `${backendURL()}${
            language ? `/${language.id}` : ""
          }/api-v1/GetNodeByURL${homePath}/$`
        );
        // else if added for nested paths or other (/products/productName)
      } else {
        // if path exists, we want to use path. If path does not exist, we want to use pathname. Path is defaulted as null and will only update if it meets the conditional on line 16.
        setURL(
          `${backendURL()}${
            language ? `/${language.id}` : ""
          }/api-v1/GetNodeByURL/${path}/$`
        );
      }
    };

    if (location && languages && regions && region) {
      if (
        !location.state ||
        location.state.from !== "landing_page" ||
        history.action === "POP"
      ) {
        checkForForwarding();
      }
    }
  }, [
    location,
    language,
    languages,
    routes,
    setStatus,
    region,
    regions,
    history,
  ]);

  return { url, setURL };
};

export default usePageURL;
