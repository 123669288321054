
const formatFeatTwoA = (featTwoAData) => {
  const data = featTwoAData.content || {};

  let {header, subHeader, image, background_image, enable_parallex_effect, children} = data;

  image = {
    src: image?.src,
    alt: image?.altText,
  };

  background_image = background_image?.source;
 
  const content = {
    header,
    subHeader,
    image,
    background_image,
    enable_parallex_effect,
    children,
  };

  return {
    type: featTwoAData.type,
    content,
    theme: featTwoAData.theme || 'theme-1',
  };
};

export default formatFeatTwoA;
