import React from "react";

export default function Parallex(props) {
  const { header,image } = props.content;
  return (
    <div
      className="react-reveal ter-variable-card ter-variable-card--not-deployed"
      style={{
        backgroundColor: "transparent",
        animationFillMode: "both",
        animationDuration: "0ms",
        animationDelay: "0ms",
        animationIterationCount: 1,
        opacity: 1,
        animationName: "react-reveal-466845260446205-1",
      }}
    >
      <div
        className="card-image-container"
        tabIndex
      >
        <img
          className="card-image"
          alt={image.alt}
          src={image.source}
        />
      </div>
      <div className="ter-variable-card__body">
        <h4
        style={{color:props.theme?.color}}
          className="ter-card__text"
        >
          {header}
        </h4>
      </div>
    </div>
  );
}
