import { Hero1 } from "luna-one";
import selectedRegion from "../../../utils/helpers/selectedRegion";
import ContentSection from "../../CustomComponents/Content-Section/ContentSection";
import FormSection from "../../CustomComponents/FormSection/FormSection";
import Stack from "../../Stack/Stack";

export default function WebinarDetail({ content }) {
  const {
    body,
    image,
    name,
    sub_header,
    content_sections,
  } = content;

  let storedStatus = localStorage.getItem(window.location.href);
  let displayStatus = storedStatus;

  if (storedStatus == null) {
    displayStatus = "unsubmitted";
    localStorage.setItem(window.location.href, displayStatus);
  } else if (storedStatus === "submitted") {
    localStorage.setItem(window.location.href, "alerted");
  }

  const images = {
    desktop: {
      url: image?.source,
      altText: image?.alt,
    },
    tablet: {
      url: image?.source,
      altText: image?.alt,
    },
    mobile: {
      url: image?.source,
      altText: image?.alt,
    },
  };

  const heroContent = {
    images,
    header: name,
    text: sub_header,
    theme: "theme-1",
  };

  const formSection = content_sections.filter(section => section.type === "form_section")[0];
  const contentSections = content_sections.filter(section => section.type !== "form_section");

  return (
    <>
      <Hero1 content={heroContent} motion />
      <ContentSection content={body[0]?.value} />
      {content_sections.length > 0 && <Stack content={contentSections} />}
      {displayStatus === "unsubmitted" && formSection && (
        <FormSection content={formSection} backgroundColor={formSection.theme?.backgroundColor}/>
      )}
   
      {/* {(displayStatus !== "unsubmitted" || isNorthAmerica) && (
        <div
          className="webinar_video"
          dangerouslySetInnerHTML={{ __html: video_embed_code }}
        ></div>
      )} */}
    </>
  );
}
