import React from "react";
import { Hero1 } from "luna-one";
import AboutAuthor from "../../CustomComponents/About-Author/AboutAuthor";
import Stack from "../../Stack/Stack";
import ContentSection from "../../CustomComponents/Content-Section/ContentSection";

export default function ArticleDetail({content}) {

  const {
    about_author,
    hide_author_info,
    body,
    image,
    name,
    sub_header,
    content_sections,
    created_at
  } = content;

  const images = {
    desktop: {
      url: image?.source,
      altText: image?.alt,
    },
    tablet: {
      url: image?.source,
      altText: image?.alt,
    },
    mobile: {
      url: image?.source,
      altText: image?.alt,
    },
  };
  const theme1 = {
    backgroundColor: "#ffffff",
  };

  const authorData = {
    images,
    header: name,
    text: sub_header,
    theme: theme1,
  };

  return (
    <main>
      <Hero1 content={authorData} motion/>
      <ContentSection content={ body[0]?.value }/>
      {content_sections.length > 0 && <Stack publishedDate={created_at} content={content_sections}/>}
      {about_author && !hide_author_info && <AboutAuthor content={about_author} />}
    </main>
  );
}
