import formatCTAs from "../formatCTAs";
import formatListFeatThreeCards from "./formatListFeatThreeCards";

const formatListFeatThree = (
  listFeatThreeData = {},
  // TODO - default text needs to come from Drupal
  labels,
  fpoBox,
  fpoSquare
) => {
  const data = listFeatThreeData || {};
 
  const {header, subHeader, theme, type, pid} = data;
  const anchor = data.anchor_toggle && data.anchor_text ? data.anchor_text : null;

  const ctas = formatCTAs(
    data.ctas,
  );

  const content = {
    header: header,
    subHeader: subHeader,
    ctas: ctas ? ctas : null,
    type: "list_feat_3",
    cards: formatListFeatThreeCards(
      data.list,
      labels?.learnMoreText,
      fpoSquare,
    ),
  };

  return {
    type,
    theme,
    content,
    pid,
    anchor,
  };
};

export default formatListFeatThree;
