import React from "react";
import defaultBgImage from "../../../assets/images/bg-img.jpg";
import generateLink from "../../../utils/helpers/generateLink";

export default function BannerSection({ content }) {
  const {
    background_image,
    header,
    description,
    cta,
    enable_parallex_effect,
    theme,
  } = content;

  return (
    <div id="root">
      <section
        className={`ter-feat-four banner-section ${
          enable_parallex_effect ? "parallax" : ""
        }`}
        style={{
          zIndex: "auto",
          backgroundImage:
            "url('" + background_image?.source || defaultBgImage + "')",
          boxShadow: "inset 0 0 0 2000px rgb(0 0 0 / 30%)",
          color: theme?.color || "#FFF",
        }}
      >
        <div
          className="react-reveal"
          style={{
            opacity: 1,
          }}
        >
          <div className="ter-feat-four__sub-header-holder ter-feat__sub-header-container">
            <p style={{ color: theme.color }}>{header}</p>
          </div>

          <div
            style={{ width: "100%", textAlign: "center", color: theme.color }}
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>

          <section
            className="ter-cta-section"
            // style={{ flexDirection: "column" }}
          >
            {cta.text && generateLink(cta.url, cta.text, "blue_btn")}
          </section>
        </div>
      </section>
    </div>
  );
}
