import React from "react";
import "./blog_listing.css";
import applicationData from "../../../utils/helpers/applicationData";

export default function FilterSearch({
  header, 
  text,
  dropdowns, 
  search,
  setSearch,
  selectedCategory,
  handleDropdownChange
}) {

  return (
    <section className="header_filter_box">
      <div className="header_filter_container">
        <div className="blog_filter_header">
          <h3>{header}</h3>
          <p>{text}</p>
        </div>
        <div className="filter_area">
          <div className="filter_search">
            <label>{applicationData()?.searchFieldLabel || 'Search'}</label>
            <svg width="17px" height="16px">
              <path
                fillRule="evenodd"
                fill="rgb(0, 0, 0)"
                d="M12.592,10.798 C14.805,7.949 14.290,3.844 11.440,1.631 C8.591,-0.583 4.486,-0.067 2.273,2.782 C0.059,5.632 0.575,9.736 3.424,11.950 C5.783,13.781 9.082,13.781 11.440,11.950 L11.475,11.986 L14.936,15.449 C15.255,15.767 15.772,15.767 16.091,15.449 C16.410,15.130 16.410,14.612 16.091,14.294 L16.091,14.294 L12.630,10.832 L12.593,10.798 L12.592,10.798 ZM10.899,3.333 C12.810,5.244 12.810,8.343 10.898,10.255 C8.987,12.166 5.887,12.166 3.976,10.255 C2.065,8.343 2.065,5.244 3.976,3.333 C5.887,1.420 8.987,1.420 10.898,3.332 C10.899,3.332 10.899,3.332 10.899,3.333 Z"
              />
            </svg>
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="filter_select">
            <label> {applicationData()?.categoryFieldLabel || 'Category'}</label>
            <select
              onChange={(e) => {
                handleDropdownChange(e.target.value);
              }}
            >
              {dropdowns.map((index) => {
                if (selectedCategory?.id ===  index.id) {
                  return <option selected key={index.id}>{index.name}</option>;
                } else {
                  return <option key={index.id}>{index.name}</option>;
                }
              })}
            </select>
          </div>
        </div>
      </div>
    </section>
  );
}
