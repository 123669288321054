import React, { useState } from "react";
import { Hero1, Hero5 } from "luna-one";
import "../../CustomComponents/BlogCard2/BlogCard2.scss";
import Article from "../../CustomComponents/Article/Article";
import Pagination from "../../Pagination/Pagination";
import Loading from "../../Loading/Loading";

export default function ProductLanding({ content }) {
  const {
    categories,
    name,
    sub_header,
    image,
    recent_products_paginated,
    NoRecordsFoundData,
    applicationData
  } = content;

  const images = {
    desktop: {
      url: image?.source,
      altText: image?.alt,
    },
    tablet: {
      url: image?.source,
      altText: image?.alt,
    },
    mobile: {
      url: image?.source,
      altText: image?.alt,
    },
  };
  const theme1 = {
    backgroundColor: "#ffffff",
  };

  const contentData = {
    images,
    header: name,
    text: sub_header,
    theme: theme1,
  };

  const [data, setData] = useState(recent_products_paginated.data);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const pagination = recent_products_paginated.pagination;
  const [loading, setLoading] = useState(false);
  const type = "product_detail_page";

  return (
    <>
      <Hero1 content={contentData} motion />
      {loading && <Loading />}
      {data?.length > 0 && !loading && (
        <Article
          selectedCategory={selectedCategory}
          learnMoreText={applicationData?.learnMoreText || 'Learn More'}
          content={data}
        />
      )}
      {data?.length <= 0 && !loading && <Hero5 content={NoRecordsFoundData} />}
      {recent_products_paginated.pagination && <Pagination
        data={data}
        setData={setData}
        setLoading={setLoading}
        limit={pagination.itemsPerPage}
        fetchNodes={true}
        type={type}
        totalRecords={pagination.total}
        category={selectedCategory}
      />}
    </>
  );
}
