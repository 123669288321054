import fpoSquare from "../../assets/images/fpoSquare.png";
import fpoBox from "../../assets/images/fpoBox.png";
import formatHeroOne from "./organismFormat/heroes/formatHeroOne";
import formatHeroTwo from "./organismFormat/heroes/formatHeroTwo";
import formatHeroThree from "./organismFormat/heroes/formatHeroThree";
import formatHeroFour from "./organismFormat/heroes/formatHeroFour";
import formatHeroSix from "./organismFormat/heroes/formatHeroSix";
import formatFeatOne from "./organismFormat/feats/formatFeatOne";
import formatFeatTwoA from "./organismFormat/feats/formatFeatTwoA";
import formatFeatTwoB from "./organismFormat/feats/formatFeatTwoB";
import formatFeatThree from "./organismFormat/feats/formatFeatThree";
import formatFeatFour from "./organismFormat/feats/formatFeatFour";
import formatFeatFive from "./organismFormat/feats/formatFeatFive";
import formatFeatSix from "./organismFormat/feats/formatFeatSix";
import formatFeatSixCarousel from "./organismFormat/feats/formatFeatSixCarousel";
import formatFeatSeven from "./organismFormat/feats/formatFeatSeven";
import formatListFeatOne from "./organismFormat/listFeats/formatListFeatOne";
import formatListFeatTwo from "./organismFormat/listFeats/formatListFeatTwo";
import formatListFeatThree from "./organismFormat/listFeats/formatListFeatThree";
import formatListFeatFour from "./organismFormat/listFeats/formatListFeatFour";
import formatListFeatFive from "./organismFormat/listFeats/formatListFeatFive";
import formatListFeatSix from "./organismFormat/listFeats/formatListFeatSix";
import formatListFeatSeven from "./organismFormat/listFeats/formatListFeatSeven";

const formatContentSections = (content_sections = {}, labels) => {
  // Iterates over the response data and formats it so that it can be properly consumed by our Luna organisms
  // All organisms will be returned in objects with content, theme, and type properties
  // Custom organisms are added here by creating a new file for it in the organismFormat directory, then imported here and added to the methods array leveraging the 'type' value from the JSON to determine the matching formatting method

  let sections;

  if (content_sections.main_content) {
    // Home page JSON is structured slightly differently at top level
    sections = content_sections.main_content;
  }

  // Filter out content sections with missing data (no type or corresponding method to type)
  const formattedSections = sections
    // These filters act as progressive null checking
    .filter((section) => section.type)
    // .filter((section) => {
    //   return methods[section.type];
    // })
    .map((section) => {
      if (methods[section.type])
        return methods[section.type](section, labels, fpoBox, fpoSquare);
      else return section;
    });

  return formattedSections;
};

const methods = {
  feat_1: formatFeatOne,
  feat_2a: formatFeatTwoA,
  feat_2b: formatFeatTwoB,
  feat_3: formatFeatThree,
  feat_4: formatFeatFour,
  feat_5: formatFeatFive,
  feat_6: formatFeatSix,
  f6_quotes_with_carousel: formatFeatSixCarousel,
  feat_7: formatFeatSeven,
  listfeat_1: formatListFeatOne,
  listfeat_2: formatListFeatTwo,
  listfeat_3: formatListFeatThree,
  listfeat_4: formatListFeatFour,
  listfeat_5: formatListFeatFive,
  listfeat_6: formatListFeatSix,
  listfeat_7: formatListFeatSeven,
  hero_1: formatHeroOne,
  hero_2: formatHeroTwo,
  hero_3: formatHeroThree,
  hero_4: formatHeroFour,
  hero_5: formatHeroFour,
  hero_6: formatHeroSix,
};

export default formatContentSections;
