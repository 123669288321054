import formatCTAs from "../formatCTAs";
import formatListFeatOneCards from "./formatListFeatOneCards";

const formatListFeatOne = (
  listFeatOneData = {},
  labels,
  fpoBox,
  fpoSquare
) =>
  // TODO - assign this from Drupal
  {
    const data = listFeatOneData || {};
    const ctas = formatCTAs(data.ctas);

    const anchor =
      data.anchor_toggle && data.anchor_text ? data.anchor_text : null;

    const content = {
      header: data.intro_header,
      subHeader: data.intro_description,
      ctas: ctas,
      type: "list_feat_1",
    };

    if (data?.list?.length) {
      const { cardOne, cardTwo } = formatListFeatOneCards(
        data.list,
        labels?.learnMoreText
      );
      content.cardOne = cardOne;
      content.cardTwo = cardTwo;
    }

    const theme = data.theme;
    return {
      theme,
      content,
      type: "list_feat_1",
      anchor,
    };
  };

export default formatListFeatOne;
