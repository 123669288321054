import formatLink from "../../../helpers/formatLink";

const formatListFeatFourDumplings = (
  dumplings
) => {
  return (
    dumplings?.map((dumpling) => {
      return {
        header: dumpling?.text,
        // TODO - link is NECESSARY for header to also render. Adding below for now until it is added to the JSON
        link: { url: formatLink("/") },
        size: "small",
        icon: {
          type: dumpling?.icon.trim(),
          size: "48px",
        },
      };
    }) || []
  );
};

export default formatListFeatFourDumplings;
