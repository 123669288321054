import React, { useState, useEffect, useRef } from "react";
import { Alert } from "terra-one";
import applicationData from "../../../utils/helpers/applicationData";
import selectedRegion from "../../../utils/helpers/selectedRegion";

export default function FormSection({ content }) {
  const {
    form,
    use_embed = true,
    marketo_script_id,
    marketo_form_id,
    header,
    subHeader,
    backgroundColor = "#F9F9F9",
  } = content;

  const [showAlert, setShowAlert] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // if (form.search('mktoForm_1030') !== -1 && typeof window.MktoForms2 == "object") {
    if (marketo_script_id && marketo_form_id && typeof window.MktoForms2 == "object") {
      let x = setInterval(() => {
        if (typeof window.MktoForms2 == "object" ) {
          window.MktoForms2.loadForm("//go.utilities.trimble.com", marketo_script_id, marketo_form_id);
          clearInterval(x);
        }
      }, 1000);
    }
    addRedirect();
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({  behavior: "smooth", block: "center"});
  };

  function addRedirect() {
    const form = document.getElementById("formData")?.querySelector("form");

    if (form !== null) {
      if (
        use_embed === false ||
        (use_embed &&
          form.querySelector('input[name="retURL"]')?.value === "http://")
      ) {
        form.addEventListener("submit", function(event) {
          event.preventDefault();
          submitGeneralForm(form);
        });
      }
    }

    function submitGeneralForm(form) {
      var elements = form.querySelectorAll("input, select, textarea");
      var formData = new FormData();

      for (var i = 0; i < elements.length; i++) {
        if (elements[i].type !== "submit" && elements[i].type !== "radio") {
          formData.append(elements[i].name, elements[i].value);
        } else if (elements[i].type === "radio" && elements[i].checked) {
          formData.append(elements[i].name, elements[i].value);
        }
      }

      var xmlHttp = new XMLHttpRequest();
      let region = selectedRegion();
      let newRegion = JSON.stringify({ id: region.id, name: region.name });

      xmlHttp.onreadystatechange = function() {
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
          setShowAlert(true);
          scrollToBottom();
          form.reset();
        }
      };
      xmlHttp.open("post", form.action);
      xmlHttp.setRequestHeader("Region", newRegion);
      xmlHttp.send(formData);
    }
  }

  return (
    <div className="form_box" style={{ backgroundColor: `${backgroundColor}` }}>
      <section className="ter-filter-hero" id="Filter-Hero Header">
        {header && <h2>{header}</h2>}
        {subHeader && <h4>{subHeader}</h4>}
        <div
          className="form_contact_box"
          id="formData"
          dangerouslySetInnerHTML={{ __html: form }}
        ></div>
        {showAlert && (
          <div ref={messagesEndRef}>
            <Alert
              text={
                applicationData()?.formSubmissionMessage ||
                "Your inquiry has been submitted and we will be in touch soon"
              }
              type="success"
              onClick={() => setShowAlert(false)}
            />
          </div>
        )}
      </section>
    </div>
  );
}
