import formatLink from "../helpers/formatLink";

const formatMenus = (responseData, language = "en", languageEnabled) => {
  const data = responseData || [];
  const languageCode = language?.id || "en";
  
  // Format menu JSON for the NavBar
  const menusSetByType = setMenusByType(data) || [];
  const organizedMenus = menusSetByType.map((menu = {}) => {
    const organizedMenu = {
      text: menu.title,
      type: menu.type,
    };

    if (menu.type === "link") {
      organizedMenu.url =
        menu.url[0] === "/"
          ? formatLink(menu.url)
          : menu.url;
    }

    if (menu.type === "mini menu") {
      organizedMenu.links = organizeMiniMenu(
        menu,
        languageCode,
        languageEnabled
      );
    }

    if (menu.type === "mega menu") {
      // Format the MegaMenu content
      organizedMenu.bottomLink = getBottomLink(
        menu,
        languageEnabled,
        languageCode
      );

      organizedMenu.links = organizeMegaMenu(
        menu,
        languageCode,
        languageEnabled
      );
    }
    return organizedMenu;
  });

  return organizedMenus;
};

const getBottomLink = (menu, languageEnabled, languageCode) => {
  // Finds the child that doesn't have children but does have a url/title and assigns it to he bottom link in the mega menu.
  const bottomLinkChild = menu?.children?.find(
    (child) => !child?.children?.length
  );

  if (bottomLinkChild?.url && bottomLinkChild?.title) {
    return {
      url: formatLink(bottomLinkChild.url),
      text: bottomLinkChild.title,
    };
  } else {
    return null;
  }
};

const setMenusByType = (data) => {
  const menus = data
    ? data.map((point = {}) => {
        if (point.children) {
          // Check to see if the object's children array has nested children array and define as a mega/mini menu
          const megaMenu = checkforMegaMenu(point.children);
          if (megaMenu) {
            point.type = "mega menu";
          } else {
            point.type = "mini menu";
          }
        } else {
          // If the object has no children, define it as a link
          point.type = "link";
        }

        return point;
      })
    : [];
  return menus;
};

const checkforMegaMenu = (data) => {
  const children = data || [];

  return children.reduce((result, child) => {
    // iterate over all the children and check for nested children arrays, returning true if any are found
    if (child && child.children) {
      result = true;
    }
    return result;
  }, false);
};

const organizeMegaMenu = (menuData, languageCode = "en", languageEnabled) => {
  const menu = menuData || {};
  // format the mega menu object to fit the NavBar

  return (menu.links = menu.children
    ? menu.children
        .filter((child) => {
          // removes the bottom link
          return child?.children?.length;
        })
        .map((child = {}) => {
          return {
            text: child.title,
            links: child.children
              ? child.children.map((grandchild) => {
                  return {
                    url:
                      grandchild.url[0] === "/"
                        ? formatLink(grandchild.url)
                        : grandchild.url,
                    text: grandchild.title,
                  };
                })
              : [],
          };
        })
    : []);
};

const organizeMiniMenu = (menu = {}, languageCode = "en", languageEnabled) => {
  // format the mini menu object to fit the NavBar

  return menu && menu.children
    ? menu.children.map((child = {}) => {
        return {
          url:
            child.url[0] === "/"
              ? formatLink(child.url)
              : child.url,
          text: child.title,
        };
      })
    : [];
};

export default formatMenus;
