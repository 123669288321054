import { Link } from "react-router-dom";
import formatLink from "./formatLink";

const generateLink = (link, text, classNames) => {
    
    if (validURL(link)) {
        return <a href={link} target="_blank"  rel="noreferrer" className={classNames}>{ text || 'Link'}</a>;
    } 
    
    return <Link to={formatLink(link)} className={classNames}>{text}</Link>;
}

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

export default generateLink;