import React from "react";
import { google, ics } from "calendar-link";
import "./events.css";
import googleCalendar from "../../../assets/icon/google_calendar.png";
import appleCalendar from "../../../assets/icon/apple_calendar.png";
import outlookCalendar from "../../../assets/icon/outlook_calendar.png";
import moment from "moment";
import 'moment/min/locales.min'
import applicationData from "../../../utils/helpers/applicationData";
import getCalendars from "../../../utils/helpers/getCalendars";
import selectedLanguage from "../../../utils/helpers/selectedLanguage";

export default function EventCard({ content }) {
  moment.locale(selectedLanguage());

  const isIOS = () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  const handleClose = () => {
    const obj = document.getElementById("modal");
    obj.classList.remove("display-block");
    obj.classList.add("display-none");
  };

  const showDescription = (key) => {
    let data = content[key].description;
    const obj = document.getElementById("modal");

    obj.classList.remove("display-none");
    obj.classList.add("display-block");
    document.getElementById("p").innerHTML = data || "No Description";
  };

  const displayEventDates = (startDate, endDate) => {
    startDate = startDate.format("MMMM DD, YYYY");
    endDate = endDate !== null && endDate.isValid() ? endDate.format("MMMM DD, YYYY") : null;
    return (
     startDate + (endDate !== null && startDate !== endDate ? " - " + endDate : "")
    );
  }

  return (
    <section className="events_container">
      <ul className="event_list">
        {content.map((event, key) => {
         
          let startDate = moment(event.start?.date || event.start?.dateTime);
          let endDate =  event.end ? moment(event.end?.date || event.end?.dateTime) : null;
          console.log(endDate);

          if (event.organizer) {
            let category = Object.keys(getCalendars).find(key => getCalendars[key] === event.organizer.email);
          }
         
          const calenderEvent = {
            start: startDate,
            end: endDate,
            title: event.summary,
            description: event.description,
            busy: true,
          };

          return (
            <li className="row" key={key}>
              <div className="col-md-2  d-flex justify-content-center">
                <div className="calendar_box">
                  <div className="cal_month">
                    {startDate.format('MMMM')}
                  </div>
                  <div className="cal_date">{startDate.format('DD')}</div>
                  <div className="cal_year">{startDate.format('YYYY')}</div>
                </div>
              </div>

              <div className="calendar_detail  col-md-8">
                <label className="calendar_category">
                  {event.organizer?.displayName}
                </label>
                <div className="calendar_header">
                  <h4>{displayEventDates(startDate, endDate)}</h4>
                  {/* <label>Featured</label> */}
                </div>
                <p className="calendar_detail">{event.summary}</p>
                {event.description && 
                  <a
                    href="javascript:void(0);"
                    onClick={() => {showDescription(key)}}
                    className="details_btn"
                  >
                    {applicationData()?.detailsLabel || 'Details'}
                  </a>
                }
                <div className="event_add_to">
                  <span>{applicationData()?.add_to_calendar_label || 'Add To'}:</span>
                  <a
                    href={google(calenderEvent)}
                    target="_blank"
                    rel="noreferrer"
                    className="add_to_calendar"
                  >
                    <img src={googleCalendar} alt="Add to Google Calendar" />
                  </a>
                  <a
                    href={ics(calenderEvent)}
                    rel="noreferrer"
                    className="add_to_calendar"
                  >
                    <img src={outlookCalendar} alt="Add to Outlook Calendar" />
                  </a>
                  {isIOS === true && (
                    <a
                      href={ics(calenderEvent)}
                      target="_blank"
                      rel="noreferrer"
                      className="add_to_calendar"
                    >
                      <img src={appleCalendar} alt="Add to iCalendar" />
                    </a>
                  )}
                </div>
              </div>
              <div
                class="col-md-2 register-button-column"
                style={{ display: "flex", alignItems: "center" }}
              >
                {event.register_link?.length > 0 && (
                  <a
                    href={event.register_link[0].url}
                    id="my-id-1"
                    class=" float-right ter-button ter-button--primary--1"
                  >
                    Register Now
                  </a>
                )}
              </div>
            </li>
          );
        })}
      </ul>
      <div
        id="modal"
        className="modal display-none"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{applicationData()?.detailsLabel || 'Details'}</h5>
              <button type="button" className="close" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p id="p" style={{ padding: "20px" }}></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
