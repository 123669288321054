import React from "react";
import useSearchForwarding from "../../hooks/search/useSearchForwarding";
import { withRouter } from "react-router-dom";
import Loading from "../Loading/Loading";
import { Hero5, ScrollToTop, Hero2 } from "luna-one";
import "./SearchPage.scss";
import { Card, TextInput, Button } from "terra-one";
import useSearchFetch from "../../hooks/search/useSearchFetch";

const SearchPage = ({
  language = "en",
  setMetadata,
  setInnerPageNavData,
  location,
  fiveHundredData,
  history,
  languageEnabled,
  searchPageData,
}) => {
  const searchTerm = location.search.substring(1);

  // useSearchFetch makes the API call to Drupal to get data on the search string
  const { status, content } = useSearchFetch(
    searchTerm,
    language.id,
    setMetadata,
    setInnerPageNavData,
    languageEnabled,
    searchPageData
  );

  // useSearchForwarding pushes the user to a new location after they've used the search input
  // This will only appear if previous searches yield no results
  const { handleSubmit, searchString, handleChange } = useSearchForwarding(
    history,
    language.id
  );

  const renderResults = () => {
    return content.results.map((item) => {
      return (
        <Card
          content={item}
          header={item.header}
          image={item.image && item.image.url ? item.image : null}
          text={item.text}
          buttonLink={item.link}
          key={item.header}
        />
      );
    });
  };

  return (
    <main className="luna-search-page">
      <ScrollToTop />
      {status === "success" && content && content.results && (
        <>
          <Hero2
            content={{
              header: `${content.searchResultsLabel} "${searchTerm}"`,
              images: content.images,
            }}
          />
          <section className="luna-search-page-search-results">
            {renderResults()}
          </section>
        </>
      )}
      {status === "success" && content && content.noResultsLabel && (
        <>
          <Hero2
            content={{
              header: `${content.noResultsLabel} "${searchTerm}"`,
              images: content.images,
            }}
          />
          <p className="luna-search-page-search-again">{content.searchAgain}</p>
          <form onSubmit={handleSubmit} className="luna-search-page-form">
            <TextInput
              name="search"
              placeholder="search"
              value={searchString}
              inputChange={handleChange}
            />
            
            <Button
              text={content.submitText}
              className="ter-button--primary--1"
            />
          </form>
        </>
      )}

      {status === "loading" && <Loading />}
      {status === "error" && <Hero5 content={fiveHundredData} />}
    </main>
  );
};

export default withRouter(SearchPage);
