import React from "react";
import { Route, Switch } from "react-router-dom";
import Page from "../Page/Page";
import SearchPage from "../SearchPage/SearchPage";

const Routing = ({
  language,
  languages,
  region,
  setRegion,
  regions,
  routes,
  setMetadata,
  setInnerPageNavData,
  setNavCTAOverride,
  fiveHundredData,
  fourZeroFourData,
  NoRecordsFoundData,
  applicationData,
  searchPageData,
  setLanguage,
  status,
  setStatus
}) => {
  // Renders the normal Page route, which is a catchall route for generating normal pages - has no specified path
  // One-off pages that won't use Page will need their routes and page components added here - has specified path

  return (
    <Switch>
        <Route
          path={`/${language}/search`}
          render={() => (
          <SearchPage
            language={language}
            setMetadata={setMetadata}
            setInnerPageNavData={setInnerPageNavData}
            fiveHundredData={fiveHundredData}
            submitText={applicationData?.submitText}
            learnMoreText={applicationData?.learnMoreText}
            searchPageData={searchPageData}
          />
        )}
      />
      <Route
        render={() => (
          <Page
            language={language}
            languages={languages}
            region={region}
            setRegion={setRegion}
            regions={regions}
            routes={routes}
            setMetadata={setMetadata}
            fiveHundredData={fiveHundredData}
            fourZeroFourData={fourZeroFourData}
            NoRecordsFoundData={NoRecordsFoundData}
            setInnerPageNavData={setInnerPageNavData}
            setNavCTAOverride={setNavCTAOverride}
            applicationData={applicationData}
            setLanguage={setLanguage}
            status={status}
            setStatus={setStatus}
          />
        )}
      />
    </Switch>
  );
};

export default Routing;
