import React from "react";

export default function ParalexVideo({ content }) {
  const { background_image, header, video } = content;
  
  const isUploadedVideo = video.source?.includes("trimble");

  return (
    <div id="root">
      <div
        className="custom-feat"
        style={{
          backgroundImage: "url(" + background_image.source + ")",
          minHight: '500px' ,
          backgroundAttachment: 'fixed' ,
          backgroundPosition: 'center' ,
          backgroundRepeat: 'no-repeat' ,
          backgroundSize: 'cover' ,
  
        }}
      >
        <h2
          className="ter-list-feat-main__header ter-feat__header ter-parallax-heading"
          style={{ color: "#fff" }}
        >
          {header}
        </h2>
        {isUploadedVideo ? (
          <video
            // poster={video.thumbnail}
            autoPlay={false}
            controls={true}
            controlsList={"nodownload"}
            mute={true}
            width={560}
            height={315}
            src={`${video.source}#t=0.1`}
            frameBorder={0}
            allowFullScreen
          />) : (
            <iframe src={video.source} width={560} height={315} />
          )}
      </div>
    </div>
  );
}
