import { useEffect, useState } from "react";

const useErrorPageData = (data) => {
  // Responsible for formatting the 404/500 page data that is set into state by useSiteData
  const [errorPageData, setErrorPageData] = useState({});

  useEffect(() => {
    const formatErrorPageData = () => {
      const fourZeroFourData = {
        header: data.fourOhFourHeader,
        text: data.fourOhFourText || "We can’t find the page you’re looking for. Please either return to where you came from, or head over to our home page.",
        image: {
          url: data.fourOhFourImage.source,
          altText: data.fourOhFourImage.alt || "404",
        },
        ctas: { ctaOne: { url: "/", text: data.homeButtonText || "Back To Home"} },
      };

      const fiveHundredData = {
        header: data.fiveHundredHeader,
        text: data.fiveHundredText || "We can’t find the page you’re looking for. Please either return to where you came from, or head over to our home page.",
        image: {
          url: data.fiveHundredImage?.source,
          altText: data.fiveHundredImage.alt || "404",
        },
        ctas: { ctaOne: { url: "/", text: data.homeButtonText || "Back To Home" } },
      };

      const NoRecordsFoundData = {
        header: data.NRFHeader,
        text: data.NRFText || "No record exits.",
        image: {
          url: data.NRFImage?.source,
          altText: data.NRFImage.alt || "404",
        },
      };

      setErrorPageData({ fourZeroFourData, fiveHundredData, NoRecordsFoundData });
    };

    if (data && data.fourOhFourHeader) {
      formatErrorPageData();
    }
  }, [data]);
 
  return errorPageData;
};

export default useErrorPageData;
