import linkedin from "../../assets/icon/social_media/linkedin.svg"
import twitter from "../../assets/icon/social_media/twitter.svg"
import facebook from "../../assets/icon/social_media/facebook.svg"
import instagram from "../../assets/icon/social_media/instagram.svg"
import youtube from "../../assets/icon/social_media/youtube.svg"
import navLogo from "../../assets/images/trimble-logo.svg";
import formatLink from "../helpers/formatLink";

const formatFooterData = (footerData, languageCode = "en") => {
  const data = footerData || {};

  return {
    logo: formatLogo(data.logo),
    trimbleText: data.trimble_specific_text,
    trimbleLink: formatLink(data.trimble_specific_link),
    siteLink: formatLink(data.site_specific_link),
    siteText: data.site_specific_text,
    linksOneHeading: data.link_list_1_heading || 'Company',
    linksTwoHeading: data.link_list_2_heading || 'Our Businesses',
    linksOne: formatLinksList(data.link_list_1, languageCode),
    linksTwo: formatLinksList(data.link_list_2, languageCode),
    corpLinks: formatLinksList(data.trimble_bar, languageCode),
    socialLinks: formatSocialLinks(data.social),
    legalStuff: formatLegalStuff(data.trimble_bar, languageCode),
  };
};

const formatLinksList = (links = [], languageCode = "en") => {
  return links.map((link) => {
    return {
      url: link?.url ? formatLink(link.url) : null,
      text: link.text,
    };
  });
};

export const formatLogo = (data) => {
  if (data) {
    return {
      url: navLogo,
      altText: data.alt || "Trimble Logo",
    };
  } else {
    return null;
  }
}

export const formatLegalStuff = (legalStuffData, languageCode) => {
  const data = legalStuffData || [];

  return data.map((point) => {
    const formattedPoint = { text: point.text };

    if (point.url !== "<nolink>") {
      formattedPoint.url = point.url? formatLink(point.url) : null;
    }

    return formattedPoint;
  });
};

export const formatSocialLinks = (socialLinkData) => {
  const data = socialLinkData || {};
  const keys = Object.keys(data);
  console.log(keys);
  return keys
    .filter(key => data[key].length > 0)
    .map((key) => {
      return {
        icon: {
          altText: `${key} logo`,
          url: socialIcons[key],
        },
        links: data[key],
      };
    });
};

const socialIcons = {
  facebook,
  twitter,
  linkedin,
  youtube,
  instagram,
};

export default formatFooterData;
