import { useEffect, useState } from "react";
import backendURL from "../../utils/helpers/backendURL";

const useLanguages = () => {
  // Fetches the data for all available translations for the site
  // Sets the active language translation into state
  const [languages, setLanguages] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const url = `${backendURL()}/en/api-v2/FetchLanguages`;
      try {
        const response = await fetch(url);
      
        const data = await response.json();

        setLanguages(
          data.map((language) => {
            return {
              id: language.id,
              name: language.name,
            };
          })
        );
         
      } catch (e) {
        console.log(e);
      }
    };

    getData();
  }, []);
  return { languages, setLanguages };
};

export default useLanguages;
