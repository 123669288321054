import React, { useState } from "react";
import FilterSearch from "../../CustomComponents/FilterSearch/FilterSearch";
import "../../CustomComponents/BlogCard2/BlogCard2.scss";
import Article from "../../CustomComponents/Article/Article";
import Pagination from "../../Pagination/Pagination";
import { Hero5 } from "luna-one";
import Loading from "../../Loading/Loading";
import chosenCategory from "../../../utils/helpers/chosenCategory";

export default function BlogLanding({ content }) {
  const {
    categories,
    featured_posts,
    name,
    sub_header,
    recent_posts_paginated,
    NoRecordsFoundData,
    applicationData
  } = content;

  let filteredCategories = categories.filter(category => !['Julkishallinto', 'Kaukolämpö', 'Sähkönjakelu', 'Vesihuolto'].includes(category.name))

  let params = new URLSearchParams(window.location.search);
  let page = params.get("page");
  let limit = params.get("limit");
  let tag = params.get("tag");

  const [data, setData] = useState(recent_posts_paginated.data);
  const [featured, setFeatured] = useState(featured_posts);
  const [search, setSearch] = useState(params.get("search"));
  const [pagination, setPagination] = useState(
    recent_posts_paginated.pagination
  );
  const [selectedCategory, setSelectedCategory] = useState(
    chosenCategory(categories, params.get("category"))
  );
  const [loading, setLoading] = useState(false);
  const type = "blog_detail_page";

  async function handleDropdownChange(selection) {
    if (selection === "All") {
      setSelectedCategory(selection);
    } else {
      let selectedCategory = categories.filter((category) => {
        return category.name === selection;
      })[0];
      setSelectedCategory(selectedCategory);
    }
  }

  return (
    <>
      <FilterSearch
        header={name}
        text={sub_header}
        handleDropdownSelect={handleDropdownChange}
        dropdowns={[{ id: 1, name: applicationData.allLabelText || 'All' }, ...filteredCategories]}
        handleDropdownChange={handleDropdownChange}
        search={search}
        setSearch={setSearch}
        selectedCategory={selectedCategory}
      />
      {loading && <Loading />}
      {data?.length > 0 && !loading && (
        <Article
          selectedCategory={selectedCategory}
          learnMoreText={applicationData.learnMoreText}
          content={data}
          Featured={featured}
        />
      )}
      {data?.length <= 0 && !loading && <Hero5 content={NoRecordsFoundData} />}
      <Pagination
        data={data}
        setData={setData}
        setFeatured={setFeatured}
        setLoading={setLoading}
        limit={pagination.itemsPerPage}
        fetchNodes={true}
        type={type}
        totalRecords={pagination.total}
        category={selectedCategory}
        search={search}
        tag={tag}
        initialPage={parseInt(page)}
      />
    </>
  );
}
