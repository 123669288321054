// Popup.js
import React, { useState } from 'react';
import './popup.scss';
import formatLink from '../../utils/helpers/formatLink';
import selectedRegion from '../../utils/helpers/selectedRegion';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Popup = ({ applicationData }) => {
  const [showModal, setShowModal] = useState(true);
  const { contact_us_url } = selectedRegion();
  const history = useHistory();  // Initialize the history object

  const handleClose = () => {
    setShowModal(false);
  };

  const handleContactUs = () => {
    history.push(formatLink(contact_us_url));
    setShowModal(false);
  };

  return (
    <div className={`custom-modal ${showModal ? 'visible' : 'hidden'}`}>
      <div className="modal-content">
        <button className="close-button" onClick={handleClose}>
          <span>&times;</span>
        </button>
        <div className="modal-body">
          <img
            src={applicationData?.modalPicture?.source} // Replace with your image URL
            alt="Modal Image"
            className="modal-image"
          />
          <p className="modal-heading">{applicationData?.modalHeading}</p>
          <p className="modal-paragraph">
            {applicationData?.modalParagraph2}
          </p>
          <p className="modal-paragraph">
            {applicationData?.modalParagraph[0]?.value}
          </p>
          <button className="contact-button" onClick={handleContactUs}>Contact us</button>
        </div>
      </div>
    </div>
  );
};

export default Popup;