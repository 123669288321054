const regionLanguageMapping = {
  DACH: "de",
  Finland: "fi",
  "North America": "en",
  Europe: "en",
  Sweden: "sv",
  "Australia & New Zealand": "en",
  Norway: "no",
  Poland: "pl",
};

export default regionLanguageMapping;
