import React from "react";
import loadingImage from "../../assets/images/loading-mini.gif";
import "./Loading.scss";

const Loading = () => {
  return (
    <div className="luna__loading-image-container">
      <img
        src={loadingImage}
        alt="dancing dots"
        className="luna__loading-image"
      />
    </div>
  );
};

export default Loading;
