import React from "react";

export default function ContactCards({content}) {
  const { contact_info_card, online_resources_card } = content;

  return (
    <div
      className="ter-list-feat-main false ter-list-feat-main--modal-hidden contact_main"
      style={{ zIndex: "auto", backgroundColor: "rgb(235, 234, 240)" }}
    >
      <div
        className="react-reveal"
        style={{
          animationFillMode: "both",
          animationDuration: "1000ms",
          animationDelay: "0ms",
          animationIterationCount: 1,
          opacity: 1,
          animationName: "react-reveal-290074148411893-1",
        }}
      ></div>
      <section className="ter-list-feat-six" style={{ zIndex: "auto" }}>
        <div
          className="react-reveal ter-list-feat-six__card"
          style={{
           
            animationFillMode: "both",
            animationDuration: "1000ms",
            animationDelay: "200ms",
            animationIterationCount: 1,
            opacity: 1,
            animationName: "react-reveal-290074148411893-1",
          }}
        >
          <h4>{online_resources_card.heading}</h4>

          {online_resources_card.links.map((index, key) => {
            return (
              <>
                <a href={index.url} key={key}>
                  {index.text}
                </a>
                <br></br>
              </>
            );
          })}
        </div>

        <div
          className="react-reveal ter-list-feat-six__card"
          style={{
            animationFillMode: "both",
            animationDuration: "1000ms",
            animationDelay: "800ms",
            animationIterationCount: 1,
            opacity: 1,
            animationName: "react-reveal-290074148411893-1",
          }}
        >
          <h4>{contact_info_card.heading}</h4>
          {contact_info_card.items?.map((index, key) => {
            return (
              <p key={key}>
                <b>{index.heading}</b>
                <br></br>
                <div dangerouslySetInnerHTML={{ __html: index.description }} />
             
              </p>
            );
          })}
        </div>
      </section>
    </div>
  );
}
