import formatLink from "../../../helpers/formatLink";

const formatFeatSixCarousel = (
  featSixCarouselData = {},
  languageCode,
  languageEnabled,
  labels,
  fpoBox,
  fpoSquare
) => {

  const carouselData = featSixCarouselData.quote_section || {};
  const type = featSixCarouselData.type;
  const theme = featSixCarouselData.theme;

  const content = carouselData.map(data => {
    const quote = {
      text: data.quote_body || null,
      source: data.quote_author || null,
      sourceTitle: data.quote_author_title || null,
    };

    const image = {
      src: data?.quote_image?.source || fpoSquare,
      alt: data?.quote_image?.alt || "Double quotes",
    };

    const cta = {
      url: data?.quote_cta?.url
        ? formatLink(data.quote_cta.url)
        : null,
      text: data?.quote_cta?.text,
    };

    const content = {
      quote,
      image,
      cta,
    };
    return content;
  })

  return {
    content,
    type,
    theme
  }
}

export default formatFeatSixCarousel;