import formatCTAs from "../formatCTAs";
import defaultBgImage from "../../../../assets/images/dummy.jpg";
const formatHeroOne = (
  heroOneData = {},
  labels,
  fpoBox,
) => {
  const data = heroOneData || {};
  const theme = data.theme;
  const type = data.type;

  const ctas = formatCTAs(data.ctas);
  
  const content = {
    header: data.header,
    text: data.subheader,
    images: formatHeroOneImages(data),
    ctas: ctas,
  };

  return { content, type, theme };
};

const formatHeroOneImages = (data = {}) => {
  const desktop = {
    src: data?.image_large?.source || defaultBgImage,
    alt: data?.image_large?.alt,
  };

  const tablet = {
    src: data?.image_medium?.source || defaultBgImage,
    alt: data?.image_medium?.alt,
  };

  const mobile = {
    src: data?.image_small?.source || defaultBgImage,
    alt: data?.image_small?.alt,
  };

  return {
    desktop: desktop.url || desktop.src ? desktop : null,
    tablet: tablet.url || tablet.src ? tablet : null,
    mobile: mobile.url || mobile.src ? mobile : null,
  };
}

export default formatHeroOne;
