import formatCTAs from "../formatCTAs";

const formatHeroThree = (
  heroThreeData,
  labels,
  fpoBox,
) => {
  const data = heroThreeData || {};
  const header = data.header;
  const text = data.description;

  // Currently don't have CTAs in the JSON to write against
  const ctas = formatCTAs(data.ctas) || {};

  const youTubeURL = "https://www.youtube.com/embed/";
  const vimeoURL = "https://vimeo.com";

  const video = {
    url:
      data?.video?.source === "youtube"
        ? `${youTubeURL}/${data?.video?.code}`
        : data?.video?.source === "vimeo"
        ? `${vimeoURL}/${data?.video?.code}`
        : null,
    allowFullScreen: true,
    uuid: data?.video?.source === "vidyard" ? data?.video?.code : null,
  };

  const thumbnail = {
    src: data?.video?.poster?.source,
    alt: data?.video?.poster?.alt,
  };

  const theme = data.theme;
  const type = data.type;
  const content = { header, text, video, thumbnail, ctas };

  return { content, theme, type };
};

export default formatHeroThree;
