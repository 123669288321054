import React from "react";
import Webinar from "./Webinar";

export default function AllWebinarList({content}) {
  const { header, cards } = content;

  return (
    <div id="root">
      <div
      className="ter-list-feat-main false ter-list-feat-main--modal-hidden"
      style={{ zIndex: "auto", backgroundColor: "rgb(255, 255, 255)" }}
      >
        <div
           className="react-reveal"
           style={{
             animationFillMode: "both",
             animationDuration: "0ms",
             animationDelay: "0ms",
             animationIterationCount: 1,
             opacity: 1,
             animationName: "react-reveal-466845260446205-1",
          }}
        >
          <h2
            className="ter-list-feat-main__header ter-feat__header"
            style={{ color: "rgb(54, 53, 69)" }}
          >
            {header}
          </h2>
        </div>
        <section className="ter-list-feat-seven">
          {cards.map((index, key) => {
            return (<Webinar key={key} content = {index}/>);
          })}
        </section>
      </div>
    </div>
  );
}
