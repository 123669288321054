import React from "react";
import "./Feat6.css";
import "./QouteCarousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Feat6 } from "luna-one";

export default function QouteCarousel({ content }) {

  return (
    <>
      {content?.content && (
        <Carousel
          swipeable
          infiniteLoop
          internal={1000}
          showStatus={false}
          showArrows={false}
        >
          {content?.content.map((section, index) => {
            return <Feat6 key={index} content={section} motion />;
          })}
        </Carousel>
      )}
    </>
  );
}
