import React, { useState } from "react";
import { FilterHero } from "luna-one";
import "../../CustomComponents/BlogCard2/BlogCard2.scss";
import Pagination from "../../Pagination/Pagination";
import chosenCategory from "../../../utils/helpers/chosenCategory";
import BlogCard from "../../CustomComponents/Blog_Card/BlogCard";
import EventsList from "../../CustomComponents/EventsList/EventsList";

export default function WebinarLanding({ content }) {
  const {
    categories,
    name,
    sub_header,
    select_categories_label,
    recent_webinars_paginated,
    future_webinars_paginated,
    applicationData,
  } = content;

  let params = new URLSearchParams(window.location.search);
  let categoryList = categories.map((category) => {
    return category.name;
  });

  const [data, setData] = useState(recent_webinars_paginated.data);
  const [pagination, setPagination] = useState(
    recent_webinars_paginated.pagination
  );
  const [selectedCategory, setSelectedCategory] = useState(
    chosenCategory(categories, params.get("category"))
  );
  const [loading, setLoading] = useState(false);
  const type = "webinar_detail_page";

  const future_webinars = future_webinars_paginated.data
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  async function handleDropdownChange(selection, name) {
    if (selection === "All" || selection === "hide") {
      setSelectedCategory(selection);
    } else {
      let selectedCategory = categories.filter((category) => {
        return category.name === selection;
      })[0];
      setSelectedCategory(selectedCategory);
    }
  }

  const dropdownOne = {
    options: categoryList,
    defaultText: applicationData.allLabelText || 'All',
    name: select_categories_label,
    selection: selectedCategory?.name,
  };

  return (
    <>
      <FilterHero
        header={name}
        text={sub_header}
        // dropdowns={[dropdownOne]}
        handleDropdownSelect={handleDropdownChange}
      />
      {future_webinars.length > 0 && (
        <EventsList
          header={applicationData.upcomingWebinarsHeading || "Upcoming Webinars"}
          data={future_webinars}
          type={"webinars"}
          backgroundColor="#FFF"
        />
      )}
      {data.length > 0 && !loading && (
        <BlogCard
          header={applicationData.allWebinarsHeading || "All Webinars"}
          selectedCategory={selectedCategory}
          cards={data}
        />
      )}
      <Pagination
        data={data}
        setData={setData}
        setLoading={setLoading}
        limit={pagination.itemsPerPage}
        fetchNodes={true}
        type={type}
        totalRecords={pagination.total}
        category={selectedCategory}
      />
    </>
  );
}
