import React from "react";
import generateLink from "../../../utils/helpers/generateLink";
import "./SocialMedia.css";

export default function SocialMedia({ linkList }) {

  return (
    <>
      <section className="footer_social">
        <div className="footer_social_container">
          {linkList.map((list, index) => {
            return (
              <div className="footer_icon_box">
                <div className="footer_social_links">
                  <ul>
                  {list?.links?.map((link, index) => {
                    return (
                      <li>
                        {generateLink(link.url, link.text)}
                      </li>
                    );
                  })}
                  </ul>
                </div>
                <img
                  src={list.icon.url}
                  className="social_img"
                  alt={list.icon.altText}
                />
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}
