import React from "react";
import { withRouter } from "react-router-dom";
import usePageURL from "../../hooks/page/usePageURL";
import usePageFetch from "../../hooks/page/usePageFetch";
import usePageFormat from "../../hooks/page/usePageFormat";

import Stack from "../Stack/Stack";
import { Hero5, ScrollToTop } from "luna-one";
import Loading from "../Loading/Loading";

const Page = ({
  language,
  languages,
  region,
  regions,
  routes,
  location,
  history,
  setMetadata,
  setInnerPageNavData,
  fiveHundredData,
  fourZeroFourData,
  NoRecordsFoundData,
  setNavCTAOverride,
  applicationData,
  setRegion,
  setLanguage,
  status,
  setStatus
}) => {
  // Get page data from drupal
  // Set data into state - content object into state and metadata into context
  // Track fetch status - success, loading, error, 404
  // Render the Stack, which is responsible for iterating over all content pulled down from Drupal and rendering corresponding organisms
  // usePageURL determines the new URL required for making a fetch call
  const { url, setURL } = usePageURL(
    location,
    language,
    languages,
    region,
    setRegion,
    regions,
    routes,
    setStatus,
    true,
    setLanguage
  );

  if (
    fourZeroFourData &&
    fourZeroFourData.ctas &&
    fourZeroFourData.ctas.ctaTwo
  ) {
    fourZeroFourData.ctas.ctaTwo.onClick = history.goBack;
  }
  // usePageFetch fetches data with the new URL and sets the URL back to null and sets the status to loading or error
  const { response, setResponse } = usePageFetch(
    url,
    setStatus,
    setURL,
    regions,
    region,
    setRegion,
    location,
    history
  );
  // usePageFormat formats the response data, returns it as content, and sets status to success or error
  // Custom organisms will need supporting logic added in here
  const { content } = usePageFormat(
    response,
    setMetadata,
    setStatus,
    setResponse,
    setInnerPageNavData,
    language,
    applicationData
  );
  if (content) {
    content.setURL = setURL;
    content.fourZeroFourData = fourZeroFourData;
    content.NoRecordsFoundData = NoRecordsFoundData;
  }

  return (
    <main className="luna__page">
      <ScrollToTop />
      {status === "200" && (content.length > 0 || content.content_sections) && (
        <Stack content={content} />
      )}
      {status === "loading" && <Loading />}
      {status === "404" && status !== "loading" && (
        <Hero5 content={fourZeroFourData} />
      )}
      {status === "200" && (!content || content.length === 0) && (
        <Hero5 content={NoRecordsFoundData} />
      )}
      {status === "error" && <Hero5 content={fiveHundredData} />}
    </main>
  );
};

export default withRouter(Page);
