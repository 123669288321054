import React, { useEffect, useState } from "react";
import "./App.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";
import useSiteData from "../../hooks/useSiteData";
import useRoutes from "../../hooks/useRoutes";
import useErrorPageData from "../../hooks/useErrorPageData";
// import useSearchPageData from "../../hooks/search/useSearchPageData";
import Template from "../Template/Template";
//language hooks
import useLanguages from "../../hooks/language/useLanguages";
import useLanguage from "../../hooks/language/useLanguage";
//region hooks
import useRegion from "../../hooks/region/useRegion";
import useRegions from "../../hooks/region/useRegions";
//navbar hooks
import useNavURL from "../../hooks/navbar/useNavURL";
import useNavResponse from "../../hooks/navbar/useNavResponse";
import useNavData from "../../hooks/navbar/useNavData";
//footer hooks
import useFooterURL from "../../hooks/footer/useFooterURL";
import useFooterResponse from "../../hooks/footer/useFooterResponse";
import useFooterData from "../../hooks/footer/useFooterData";
import { useHistory } from "react-router";
import Popup from "../DynamicPopup/Popup";

const App = () => {
  // Fetches languages and sets current language
  // Generates data needed for routes
  // Fetches site application data - this will include standard CTA labels, the data for 404 and 500 pages, and other site-specific required data
  // Fetches navbar data
  // Fetches footer data
  // Renders the Template, which renders Helmet, Navbar, Footer, and Routing
  let history = useHistory();
  const { region, setRegion } = useRegion();
  const regionData = useRegions(region, setRegion);
  const { regions } = regionData;
  const { languages } = useLanguages();
  const { language, setLanguage } = useLanguage(languages, region, setRegion, regions);
  const [redirectURL, setRedirectURL] = useState(false);

  const routesData = useRoutes();

  const applicationData = useSiteData(language);

  const {
    fourZeroFourData,
    fiveHundredData,
    NoRecordsFoundData,
  } = useErrorPageData(applicationData);
  // // NAVBAR DATA MANAGEMENT
  const { navURLs, setNavURLs } = useNavURL(language, region);

  const { mainNavResponse, setMainNavResponse } = useNavResponse(
    navURLs,
    setNavURLs,
    language,
    region
  );
  if (mainNavResponse !== null) {
    // console.log(mainNavResponse);
  }

  const { navData } = useNavData(
    mainNavResponse,
    setMainNavResponse,
    setNavURLs,
    applicationData,
    language
  );

  console.log('nav data changing', mainNavResponse, language);

  // FOOTER DATA MANAGEMENT //
  const { footerURL, setFooterURL } = useFooterURL(language, region);

  const { footerResponse, setFooterResponse } = useFooterResponse(footerURL);

  const { footerData } = useFooterData(
    language,
    footerResponse,
    setFooterURL,
    setFooterResponse
  );

  const regionEnabled = true;
  const languageEnabled = true;

  setTimeout(() => {
    const asides = document.getElementsByClassName(
      "ter-hero-one__content-container"
    );

    if (asides[0]) {
      if (window.innerWidth > 600) {
        asides[0].style.transform = "translate(48px, -300px)";
      }
    }
    // scroll to an element
    const currUrl = window.location.href;
    const urlParts = currUrl.split("#");
    if (urlParts[1] !== null && document.getElementById(urlParts[1]) !== null) {
      let element_scroll = document.getElementById(urlParts[1]);
      element_scroll.style.scrollMarginTop = '70px';
      element_scroll.scrollIntoView();
    }
  }, 1000);
  (function () {
    var parallax = document.getElementsByClassName("parallax"),
      speed = 10;

    window.onscroll = function () {
      [].slice.call(parallax).forEach(function (el, i) {
        var windowYOffset = window.scrollY,
          diff = windowYOffset - el.offsetTop,
          yPos = -(windowYOffset / speed),
          elBackgrounPos = "50% " + yPos + "px";
        console.log(windowYOffset, elBackgrounPos);
        el.style.backgroundPosition = elBackgrounPos;
      });
    };
  })();
  // // SEARCH PAGE DATA MANAGEMENT //
  // const { searchPageData } = useSearchPageData(applicationData);

  const hitUrl = document.location.host;
  const urlPathname = window.location.pathname;
  const urlPathnameArr = urlPathname.split("/");
  const allLangs = ["en", "sv", "de", "fi", "no", "pl"];
  const allRegions = ["au", "us", "eu", "se", "de", "fi", "no", "pl"];
  const allRegionsFull = ["en-au", "en-us", "en-eu", "sv-se", "de-de", "fi-fi", "no-no", "pl-pl"];
  const regLangs = { "au": "en", "us": "en", "eu": "en", "se": "sv", "de": "de", "fi": "fi", "no": "no", "pl": "pl" };
  const regToUtilities = ["au", "us", "se", "de", "no"];
  const regNoRedirect = ["fi", "eu", "pl"];
  const regNoRedirectFull = ["fi-fi", "en-eu", "pl-pl"];
  const regToUPA = ["fi", "eu"];
  const regToUPAFull = ["fi-fi", "en-eu"];

  useEffect(() => {
    if (region) {
      const urlLangReg = ['en-us', 'fi-fi'];
      const preUrl = ['products', 'webinars', 'solutions', 'tuotteet', 'lataa-iot-webinaaritallenne', 'telog-pfe32a'];
      const baseUrlArr = ['utilities.trimble.com', 'upa.trimble.com'];
      if(baseUrlArr.includes(hitUrl) && (urlPathnameArr[1] && urlLangReg.includes(urlPathnameArr[1])) && (urlPathnameArr[2] && preUrl.includes(urlPathnameArr[2]))){
          var redirectArr = new Map([
            ['telog-hpr32a', '/products/pressure-and-leak-monitoring/general-pressure/hpr-32a/'],
            ['telog-hpr32ia', '/products/pressure-and-leak-monitoring/transient-management/hpr-32ia/'],
            ['telog-ilr32a', '/products/pressure-and-leak-monitoring/general-pressure/ilr-32a/'],
            ['telog-pfe32a', '/products/pressure-and-leak-monitoring/general-pressure/pfe-32a/'],
            ['telog-pr32a-pr32ia', '/products/pressure-and-leak-monitoring/general-pressure/pr-32a/'],
            ['telog-pr32a-pr32ia', '/products/pressure-and-leak-monitoring/transient-management/pr-32ia/'],
            ['raven-eye-2', '/products/meters/open-channel-flow-meters/raven-eye-2/'],
            ['telog-rg32a', '/products/pressure-and-leak-monitoring/general-pressure/rg-32a/'],
            ['telog-rs45', '/products/pressure-and-leak-monitoring/general-pressure/rs-45/'],
            ['Telog%20Ru-32imA', '/products/pressure-and-leak-monitoring/transient-management/ru-32ima/'],
            ['telog-ru32ma', '/products/pressure-and-leak-monitoring/general-pressure/ru-32ma/'],
            ['telog-ru35', '/products/pressure-and-leak-monitoring/general-pressure/ru-35/'],
            ['trimble-unity-rm', '/products/analytics-software/remote-monitoring-software/'],
            ['remotemonitoring', '/products/pressure-and-leak-monitoring/transient-management/telog/'],
            ['system-action-real-time-iot-performance-intelligent-asset-management', '/products/pressure-and-leak-monitoring/'],
            ['trimble-unity-and-cityworks-ams-iot-solution', '/products/pressure-and-leak-monitoring/'],
            ['remotemonitoring', '/products/pressure-and-leak-monitoring/'],
            ['iotRemoteMonitoring', '/products/pressure-and-leak-monitoring/'],
            ['remotemonitoring', '/products/pressure-and-leak-monitoring/'],
            ['remote-gas-pressure-monitoring-see-what-you-cant-see', '/products/pressure-and-leak-monitoring/'],
            ['rmsaas', '/products/pressure-and-leak-monitoring/'],
            ['remote-gas-pressure-monitoring', '/products/pressure-and-leak-monitoring/'],
            ['lataa-iot-webinaaritallenne', '/products/pressure-and-leak-monitoring/'],
            ['iot', '/products/pressure-and-leak-monitoring/']
        ]);
        const baseRedirectUrl = 'https://www.badgermeter.com';
        if (urlPathnameArr[3] && redirectArr.has(urlPathnameArr[3])) {
            //Special case
            if(urlPathnameArr[2] == 'solutions' && urlPathnameArr[3] == 'remotemonitoring'){
              window.location.href = baseRedirectUrl + '/products/pressure-and-leak-monitoring/';
            }else if(urlPathnameArr[2] == 'products' && urlPathnameArr[3] == 'remotemonitoring'){
              window.location.href = baseRedirectUrl + '/products/pressure-and-leak-monitoring/transient-management/telog/';
            }else{
              window.location.href = baseRedirectUrl + redirectArr.get(urlPathnameArr[3]);
            }
        }else if((!urlPathnameArr[3] && urlPathnameArr[2] == 'lataa-iot-webinaaritallenne')){
            window.location.href = baseRedirectUrl + redirectArr.get(urlPathnameArr[2]);
        }else if((!urlPathnameArr[3] && urlPathnameArr[2] == 'telog-pfe32a')){
          window.location.href = baseRedirectUrl + redirectArr.get(urlPathnameArr[2]);
      }
      }
      if (hitUrl == 'upa.trimble.com' && (urlPathname !== '' && urlPathname !== '/')) {//replace upa.tcsengine.com with upa.trimble.com
        if ((regNoRedirect.includes(urlPathnameArr[1]) || regNoRedirectFull.includes(urlPathnameArr[1]))) {
          //Do nothing
          setRedirectURL(true);
        } else if (!allRegions.includes(urlPathnameArr[1]) && !allRegionsFull.includes(urlPathnameArr[1]) && region.region_code) {
          if (allLangs.includes(urlPathnameArr[1])) {
            window.location.href = 'https://utilities.trimble.com/' + regLangs[region.region_code] + '-' + region.region_code + (urlPathname.replace("/" + urlPathnameArr[1], ""));// replace trimble.jjtestsite.us with utilities.trimble.com
          } else {
            window.location.href = 'https://utilities.trimble.com/' + regLangs[region.region_code] + '-' + region.region_code + urlPathname;// replace trimble.jjtestsite.us with utilities.trimble.com
          }
        } else {
          window.location.href = 'https://utilities.trimble.com' + urlPathname; // replace trimble.jjtestsite.us with utilities.trimble.com
        }
      } else if (hitUrl == 'upa.trimble.com' && regToUtilities.includes(region.region_code)) {//replace upa.tcsengine.com with upa.trimble.com
        window.location.href = 'https://utilities.trimble.com/' + regLangs[region.region_code] + '-' + region.region_code;// replace trimble.jjtestsite.us with utilities.trimble.com
      } else if ((hitUrl == 'utilities.trimble.com' || hitUrl == 'utilities.trimble.fi') && (urlPathname !== '' && urlPathname !== '/')) { //replace trimble.jjtestsite.us with utilities.trimble.com
        if ((regToUPA.includes(urlPathnameArr[1]) || regToUPAFull.includes(urlPathnameArr[1]))) { //for fi/eu we need to redirect to upa.trimble.com
          window.location.href = 'https://upa.trimble.com' + urlPathname;//replace upa.tcsengine.com with upa.trimble.com
        } else if ((!allRegions.includes(urlPathnameArr[1]) && !allRegionsFull.includes(urlPathnameArr[1])) && (region.region_code && regToUPA.includes(region.region_code))) {
          if (allLangs.includes(urlPathnameArr[1])) {
            window.location.href = 'https://upa.trimble.com/' + regLangs[region.region_code] + '-' + region.region_code + (urlPathname.replace("/" + urlPathnameArr[1], ""));// replace upa.tcsengine.com with upa.trimble.com
          } else {
            window.location.href = 'https://upa.trimble.com/' + regLangs[region.region_code] + '-' + region.region_code + urlPathname;// replace upa.tcsengine.com with upa.trimble.com
          }
        } else {
          //Do nothing
          setRedirectURL(true);
        }
      } else if ((hitUrl == 'utilities.trimble.com' || hitUrl == 'utilities.trimble.fi') && regToUtilities.includes(region.region_code)) {//replace trimble.jjtestsite.us with utilities.trimble.com
        if ((region.region_code && regToUPA.includes(region.region_code))) {
          window.location.href = 'https://upa.trimble.com/' + regLangs[region.region_code] + '-' + region.region_code; //replace upa.tcsengine.com with upa.trimble.com
        } else {
          //Do nothing
          setRedirectURL(true);
        }
      } else if ((hitUrl == 'utilities.trimble.se')) {
        window.location.href = 'utilities.trimble.com/sv-se' + urlPathname;
      } else if ((hitUrl == 'utilities.trimble.de')) {
        window.location.href = 'utilities.trimble.com/de-de' + urlPathname;
      } else {
        setRedirectURL(true);
      }
    }
  }, [region]);



  const [pathIncluded, setPathIncluded] = useState(false)

  const simplifiedUrls = applicationData?.pageLists?.map(page => {
    const parts = page.url.split('/');
    const simplifiedUrl = `/${parts.slice(2).join('/')}`; // Adjust the index based on your structure
    return simplifiedUrl;
  });

  console.log('Simplified URLs:', simplifiedUrls);

  useEffect(() => {
    // Function to check if the path is included in the array
    const checkPath = (pathname) => {
      const simplifiedPath = `/${pathname.split('/').slice(2).join('/')}`;
      const sanitizedPath = simplifiedPath.replace(/ /g, '%20'); // Replace spaces with %20
      const isPathIncluded = simplifiedUrls?.includes(sanitizedPath);
      console.log('Last segment:', sanitizedPath);
      console.log('Is path in array:', isPathIncluded);
      setPathIncluded(isPathIncluded);
    };
    // Check the initial location when the component mounts
    checkPath(history.location.pathname);



    // Set up the listener for subsequent location changes
    const unlisten = history.listen((location) => {
      checkPath(location.pathname);
    });

    // Cleanup function to unsubscribe from the listener when the component unmounts
    return () => {
      unlisten();
    };
  }, [history, simplifiedUrls]);


  return (redirectURL &&
    <div
      className={`App app ${languageEnabled ? "App--language-enabled" : "App--language-disabled"
        } ${regionEnabled ? "App--region-enabled" : "App--region-disabled"}`}
    >
      {language && routesData && (
        <Template
          regionData={regionData}
          routes={routesData}
          languages={languages}
          language={language}
          setLanguage={setLanguage}
          region={region}
          setRegion={setRegion}
          regions={regionData?.regions}
          navBarData={navData}
          footerData={footerData}
          fiveHundredData={fiveHundredData}
          fourZeroFourData={fourZeroFourData}
          NoRecordsFoundData={NoRecordsFoundData}
          // labels={{
          //   learnMoreText: applicationData?.learnMoreText,
          //   submitText: applicationData?.submitText,
          // }}
          applicationData={applicationData}
        // searchPageData={searchPageData}
        />
      )}
      {(applicationData && pathIncluded == true) && <Popup applicationData={applicationData} />}

    </div>
  );
};

export default App;
