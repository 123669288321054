import React from "react";
import ico from '../../../assets/images/user-profile.jpg' 
export default function ContactTrimble(props) {
  const { cards, header, icon, theme } = props.content;
  return (
    <div id="root">
      <div
        className="ter-list-feat-main false ter-list-feat-main--modal-hidden"
        style={{ zIndex: "auto", backgroundColor: theme }}
      >
        <div
          className="react-reveal"
          style={{
            animationFillMode: "both",
            animationDuration: "0ms",
            animationDelay: "0ms",
            animationIterationCount: 1,
            opacity: 1,
            animationName: "react-reveal-5934318197011-1",
          }}
        >
          <h2
            className="ter-list-feat-main__header ter-feat__header"
            style={{ color: "rgb(54, 53, 69)" }}
          >
            {header}
          </h2>
        </div>
        <section
          className="ter-list-feat-four ter-list-feat-four--default"
          style={{ zIndex: "auto" }}
        >
          <div className="ter-list-feat-four__dumpling-container">
            {cards.map((index, key) => {
              return (
                <div className="ter-feat-five__dumpling-wrapper dumping-custom ter-feat-five__dumpling-wrapper--list_feat_4">
                  <article className="ter-dumpling-custom ter-dumpling--small">
                    <div className="ter-dumpling__icon-wrapper-custom">
                      <img src={icon.source ? icon.source : ico} alt="play button" className="" />
                    </div>
                    <div>
                      <p
                        className="ter-dumpling__text"
                        style={{ color: "black" }}
                      >
                        Lorem Ipsum Lorem Ipsum
                      </p>
                    </div>
                    <div />
                  </article>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </div>
  );
}
