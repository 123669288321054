import formatLink from "../../../helpers/formatLink";

const formatListFeatOneCards = (
  listFeatOneCardData = [],
  learnMoreText = "Learn More",
) => {
  const data = listFeatOneCardData || [];

  const list =
    Object.keys(data)[0] &&
    data[Object.keys(data)[0]] &&
    data[Object.keys(data)[0]].list
      ? data[Object.keys(data)[0]].list
      : null;

  if (list) {
    const keys = Object.values(list);

    if (keys) {
      const cards = keys.map((key) => {
        return {
          header: key.header,
          text: key.description,
          cta: {
            url:
              key.cta && key.cta.url
                ? formatLink(key.cta.url)
                : null,
            text: key.cta && key.cta.text ? key.cta.text : learnMoreText,
          },
        };
      });
      return {
        cardOne: cards[0] || {},
        cardTwo: cards[1] || {},
      };
    }
  }
};

export default formatListFeatOneCards;
