const formatFeatFive = (
  featFiveData,
  languageCode,
  languageEnabled,
  labels,
  fpoBox
) => {
  const data = featFiveData || {};
  const type = data.type;
  const theme = data.theme;
  const anchor =
    data.anchor_toggle && data.anchor_text ? data.anchor_text : null;

  const youTubeURL = "https://www.youtube.com/embed";
  const vimeoURL = "https://vimeo.com";

  const content = {
    header: data.intro_header,
    subHeader: data.intro_description,
    thumbnail: {
      src: data?.video?.poster?.source || fpoBox,
      alt: data?.video?.poster?.alt || "video thumbnail",
    },
    video: {
      url:
        data?.video?.source === "youtube"
          ? `${youTubeURL}/${data?.video?.code}`
          : data?.video?.source === "vimeo"
          ? `${vimeoURL}/${data?.video?.code}`
          : null,
      allowFullScreen: true,
      uuid:
        data?.video?.code && data?.video?.source === "vidyard"
          ? data.video.code
          : null,
    },
    vidyard:
      data?.video?.source && data?.video?.source === "vidyard" ? true : false,
  };

  return {
    type,
    content,
    theme,
    anchor,
  };
};

export default formatFeatFive;
