import React from "react";
import generateLink from "../../../utils/helpers/generateLink";

export default function Webinar({content}) {
  const { header, image, description, cta } = content;
  return (
    <>
      <div
        className="react-reveal ter-variable-card"
        style={{
          // backgroundColor: "rgb(249, 249, 251)",
          animationFillMode: "both",
          animationDuration: "0ms",
          animationDelay: "0ms",
          animationIterationCount: 1,
          opacity: 1,
          animationName: "react-reveal-466845260446205-1",
        }}
      >
        <div
          className="card-image-section"
          id="id-749"
          tabIndex
        >
          <img
            className="card-image"
            alt={image.alt}
            src={image.source}
          />
        </div>
        <div className="ter-variable-card__body">
          <p
            className="ter-card__text"
            style={{
              color: "#363545",
            }}
          >
            {header}
          </p>
          <p className="ter-card__text" style={{color: "#363545"}}>
            {description}
          </p>
  
          {cta.text &&
            generateLink(
              cta.url,
              cta.text,
              "ter-button ter-button--primary--1"
            )}
        </div>
      </div>
    </>
  );
}
