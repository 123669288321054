import React from "react";
import './podCast.css'

export default function Podcast({ content }) {
  const { podcast_series, header } = content;




  return (
    <section
      style={{  padding: "10vh 0vh 0vh 0vh" }}
      className="ter-filter-hero content_social"
      id="Filter-Hero Header"
    >
      <h2
        className="ter-list-feat__header"
        style={{ color: "#505357", marginBottom: "5vh" }}
      >
        {header}
      </h2>
      {podcast_series.map((podcast) => {
        console.log(podcast.embedded_code)
        return (
          <>
            <div className="content_inner podcast_box">
              <div className="pod_cast_player"
                dangerouslySetInnerHTML={{ __html: podcast.embedded_code }}
              ></div>
              <div className="pod_cast_detail"
                
                dangerouslySetInnerHTML={{ __html: podcast.description }}
              ></div>
            </div>
            <hr className='hr'/>
          </>
        );
      })}
    </section>
  );
}
