import React from "react";
import "./blog_card.css";
import dummyImg from "../../../assets/images/dummy/no_image.jpg";
import { Link } from "react-router-dom";
import displaySelectedCategory from "../../../utils/helpers/displaySelectedCategory";
import applicationData from "../../../utils/helpers/applicationData";
import generateLink from "../../../utils/helpers/generateLink";

export default function BlogCard({
  header,
  subHeader,
  selectedCategory,
  showDate = true,
  cards,
  backgroundColor = "#F9F9FB"
}) {
  return (
    <section className="blog-cards" style={{backgroundColor: `${backgroundColor}`}}>
      {header && (
        <h2 style={{ marginBottom: "10px" }} className="ter-feat-one__header">
          {header}
        </h2>
      )}
      {subHeader && (
        <p 
          style={{ textAlign: "center", marginBottom: "50px" }}
          className="ter-hero-one__text"
        >
          {subHeader}
        </p>
      )}
      <div className="blog-cards-container">
        {cards?.map((index, key) => {
          return (
            <div key={key} className="blog-cards-box">
              <div className="blog-card-inner">
              <div class="feature-img">
              <img
                  src={index.image?.source || dummyImg}
                  alt={index.image?.alt || "image"}
                  className="imgRespons"
                />
                </div>                
                <label>
                  {displaySelectedCategory(index.categories, selectedCategory)}
                </label>

                <h4>{index?.name}</h4>
                <p className="parag">{index.summary?.slice(0, 160) || "No Description"}</p>
              </div>
              {generateLink(
                index?.read_more_url,
                applicationData()?.readMoreText || 'Read More',
                "white-btn"
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
}
