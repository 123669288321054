import { useEffect, useState } from "react";
import backendURL from "../../utils/helpers/backendURL";
import selectedLanguage from "../../utils/helpers/selectedLanguage";

const useRegions = (region, setRegion) => {
  // Fetch data for site regions and set the active region
  const [regions, setRegions] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    // Retrive the region list from Drupal and set into state
    const getRegions = async () => {
      const url = `${backendURL()}/${selectedLanguage() || 'en'}/api-v1/GetRegions`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data && data.length) {
          setRegions(data);
        } else {
          setRegions(null);
        }
      } catch (e) {
        setRegions(null);
        // for testing purposes
      }
    };

    getRegions();
  }, []);

  useEffect(() => {
    const shhhh = "5FeeMMNq0pC4YNt";
    // Retrieves the user's location for automatic region assignment and set to state
    const fetchUserLocation = async () => {
      try {
        const response = await fetch(
          `https://pro.ip-api.com/json/?key=${shhhh}&fields=status,countryCode,query&lang=`
        );
        const data = await response.json();

        if (data) {
          setUserLocation(data?.countryCode ? data.countryCode : "PK");
          if (userLocation) {
            checkForRegionMatch();
          }
        }
       else {
        console.log('setting region', regions[5]);
          setRegion(regions[5]);
        }
      } catch (error) {
        setRegion(regions[5]);
        console.log('error happening', error);
      }
    };

    const checkForRegionMatch = () => {
      if (region == null && userLocation) {
        const match = regions
          // .filter(region => !['Sweden', 'Europe'].includes(region.name))
          .reduce((match, region) => {
            var region_codes_list  = region?.iso_codes.split(",");
            if (region_codes_list.includes(userLocation)) {
              match = region;
            }
            return match;
          }, null);
        if (match) {
          setRegion(match);
        } else {
          setRegion(regions[5]);
        }
      } else {
        setRegion(regions[5])
      }
    };

    if (regions) {
      if (region == null) {
        //if region is not selected then chose region based on user location
        fetchUserLocation();
      } else {
        //if region is already stored in localstorage update its data
        let updatedRegion = regions.filter(reg => reg.id === region.id)[0];
        setRegion(updatedRegion);
      }
    }

  }, [regions, userLocation]);

  return {
    region,
    regions,
    setRegion,
    userLocation,
    setUserLocation,
  };
};

export default useRegions;
