import "./FooterHandler.scss";
import { Footer } from "luna-one";
import selectedLanguage from "../../utils/helpers/selectedLanguage";
import SocialMedia from "../CustomComponents/SocialMedia/SocialMedia";

const FooterHandler = ({ data, status }) => {
  const {
    legalStuff,
    linksOneHeading,
    linksTwoHeading,
    linksOne,
    linksTwo,
    logo,
    siteLink,
    siteText,
    socialLinks,
    trimbleLink,
    trimbleText,
  } = data;
  //manually setting footer logo link of luna
  let footerLogoLink = document.querySelector(".ter-footer__logo-wrapper a");

  if (footerLogoLink !== null) {
    footerLogoLink.href = `/${selectedLanguage()}`;
  }

  return (
    <>
      {status !== "loading" && socialLinks.length > 0 && <SocialMedia linkList={socialLinks} />}
      <style
        dangerouslySetInnerHTML={{
          __html: `
      .ter-footer__link-containers section:nth-child(1):before {
        content: '${linksOneHeading}';
    }

    .ter-footer__link-containers section:nth-child(2):before {
      content: '${linksTwoHeading}';
    }`,
        }}
      />
      <Footer
        trimbleText={trimbleText}
        trimbleLink={trimbleLink}
        siteText={siteText}
        siteLink={siteLink}
        linksOne={linksOne}
        linksTwo={linksTwo}
        logo={logo}
        socialLinks={[]}
        legalStuff={legalStuff}
      />
    </>
  );
};
export default FooterHandler;
