import formatCTAs from "../formatCTAs";
import formatListFeatSevenCards from "./formatListFeatSevenCards";

const formatListFeatSeven = (
  listFeatSevenData = {},
  labels,
  fpoBox,
  fpoSquare
) => {
  const data = listFeatSevenData || {};
  const ctas = formatCTAs(
    data.ctas
  );

  const content = {
    header: data.intro_header,
    subHeader: data.intro_description,
    type: "list_feat_7",
    ctas: ctas ? ctas : null,
    cards: formatListFeatSevenCards(
      data.list,
      fpoBox,
      fpoSquare
    ),
  };
  const theme = data.theme;
  const anchor =
    data.anchor_toggle && data.anchor_text ? data.anchor_text : null;
  return {
    theme,
    type: "list_feat_7",
    content,
    anchor,
  };
};

export default formatListFeatSeven;
