import React from "react";
import userProfileImage from "../../../assets/images/user-profile.jpg";
import applicationData from "../../../utils/helpers/applicationData";

export default function AboutAuthor({ content }) {
  const {
    about,
    // email,
    name,
    picture,
    backgroundColor = "rgb(0, 95, 158)",
  } = content;

  const aboutAuthorHeading = applicationData()?.aboutAuthorHeading || 'About The Author';

  return (
    <section
      className="about_auth"
      id="Filter-Hero Header"
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="auth_main">
        <div className="auth_name">
          <img src={picture || userProfileImage} alt="author" />
        </div>

        <div className="auth_detail">
          <h3 className="ter-hero-one__header">{aboutAuthorHeading}</h3>
          <h2>{name}</h2>
          <p dangerouslySetInnerHTML={{ __html: about }}></p>
        </div>
      </div>
    </section>
  );
}
