import formatCTAs from "../formatCTAs";
import formatHeadShotdumplings from "./formatListFeatTwoCards";

const formatListFeatTwo = (listFeatTwoData = {}, labels, fpoBox, fpoSquare) => {
  const data = listFeatTwoData || {};
  const ctas = formatCTAs(data.ctas);

  const anchor =
    data.anchor_toggle && data.anchor_text ? data.anchor_text : null;
  const content = {
    header: data?.intro_header,
    subHeader: data?.intro_description,
    type: "list_feat_2",
    ctas,
    headShotdumplings: formatHeadShotdumplings(data.list, fpoBox),
  };

  return {
    content,
    theme: data.theme || "theme-1",
    type: "list_feat_2",
    anchor,
  };
};

export default formatListFeatTwo;
