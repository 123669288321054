import React, { useState, useEffect } from "react";
import getEventByIndustry from "../../../utils/helpers/getEventByIndustry";
import Loading from "../../Loading/Loading";
import EventCard from "../Event/EventCard";
import "./EventsList.scss";

export default function EventsList({
  header,
  subHeader,
  type = "events",
  data = [],
  selectedCategory,
  backgroundColor = "#F9F9FB",
}) {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const script = document.createElement("script");
  script.src = "https://apis.google.com/js/api.js";
  document.body.appendChild(script);

  useEffect(() => {
    if (type === "events") {
      script.onload = () => {
        var gapi = window.gapi;
        gapi.load("client", getAllEvents);
      };
    } else if (type === "webinars") {
      const formattedEvents = data.map((event) => {
        return {
          summary: event.name,
          description: event.description,
          start: {
            date: event.date,
          },
          register_link: event.register_link,
        };
      });
      setEvents(formattedEvents);
      setLoading(false);
    }
  }, []);

  function getAllEvents() {
    getEventByIndustry(selectedCategory)
      .then((result) => {
        console.log(result);
        setEvents(result.slice(0, 5));
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  return (
    <>
      {events.length > 0 && (
        <section
          className="blog-cards eventsList"
          style={{ backgroundColor: `${backgroundColor}` }}
        >
          {header && (
            <h2
              style={{ marginBottom: "10px" }}
              className="ter-feat-one__header"
            >
              {header}
            </h2>
          )}
          {subHeader && (
            <p
              style={{ textAlign: "center", marginBottom: "50px" }}
              className="ter-hero-one__text"
            >
              {subHeader}
            </p>
          )}
          {loading && <Loading />}
          <div className="">
            {events.length > 0 && <EventCard content={events} />}
          </div>
        </section>
      )}
    </>
  );
}
