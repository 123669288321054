import { useEffect, useState } from "react";
import backendURL from "../utils/helpers/backendURL";

const useSiteData = (language) => {
  // Fetches general site data and sets into state
  // Data usually consists of labels like 'Submit', 'Cancel', 'Learn More', etc.
  // Data can be found in Drupal at Configuration -> Development -> Luna Variable Settings
  const [data, setData] = useState(null);
  
  useEffect(() => {
    const fetchData = async () => {
      const url = `${backendURL()}/${language.id}/api-v1/FetchAppData`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        setData(data);
        
        localStorage.setItem('applicationData', JSON.stringify(data));
      } catch (e) {
        console.log(e);
      }
    };

    if (language) {
      fetchData();
    }
  }, [language]);

  return data;
};

export default useSiteData;
