import selectedRegion from "./selectedRegion";
// TODO add real backend url and env variables
const displaySelectedCategory = (categories, selectedCategory) => {
  if (categories.length > 0) {
    if (selectedCategory && selectedCategory!=='hide') {
      let category = categories.filter(category => {
        return category.id === selectedCategory.id;
      })
      let displayedCategory = category.length > 0 ? category[0].name : categories[0].name;
      return displayedCategory.toUpperCase()
    } 
    var allCatCaption = "";
    if(selectedRegion().name === "Finland" && categories.length > 2){
      allCatCaption = "Kaikki toimialat";
    }else if(selectedRegion().name === "Sweden" && categories.length > 2){
      allCatCaption = "Alla";
    }else{
      allCatCaption = categories[0].name.toUpperCase();
    }
    return allCatCaption;
  }
  return "UNCATEGORIZED"
}

export default displaySelectedCategory;
