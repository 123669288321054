import formatCTAs from "../formatCTAs";
import formatListFeatSixCards from "./formatListFeatSixCards";

const formatListFeatSix = (
  listFeatSixData = {},
  languageCode,
  languageEnabled,
) => {
  const data = listFeatSixData || {};
  const ctas = formatCTAs(
    data.ctas,
    languageCode,
    languageEnabled,
  );

  const anchor =
    data.anchor_toggle && data.anchor_text ? data.anchor_text : null;
  const content = {
    header: data.intro_header,
    subHeader: data.intro_description,
    ctas: ctas,
    type: "list_feat_6",
    list: formatListFeatSixCards(data.list),
  };
  const theme = data.theme;
  return {
    theme,
    content,
    type: "list_feat_6",
    anchor,
  };
};

export default formatListFeatSix;
