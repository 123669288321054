import React, {useState} from "react";
import NavBarHandler from "../NavBarHandler/NavBarHandler";
import FooterHandler from "../FooterHandler/FooterHandler";
import useInnerPageNav from "../../hooks/navbar/useInnerPageNav";
import useMetadata from "../../hooks/useMetadata";
import Routing from "../Routing/Routing";
import HelmetHandler from "../HelmetHandler/HelmetHandler";
import DocumentMeta from "react-document-meta";

const Template = ({
  navBarData,
  languages,
  language,
  setLanguage,
  regionData,
  region,
  setRegion,
  regions,
  footerData,
  routes,
  fiveHundredData,
  fourZeroFourData,
  NoRecordsFoundData,
  applicationData,
  // searchPageData,
}) => {
  // Manages data for InnerPageNav and Metadata
  // Renders handlers for Helmet, NavBar, and Footer
  // Render Routing
  const { languageEnabled } = true;
  const { innerPageNavData, setInnerPageNavData } = useInnerPageNav();
  const { metadata, setMetadata } = useMetadata();
  const [status, setStatus] = useState("loading");
  const { title, canonical_url, description } = metadata;
  const meta = {
    title: title || "Trimble Utilities & Public Administration",
    description: description || "This is a page by Trimble",
    canonical: canonical_url,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  };
  // const [navCTAOverride, setNavCTAOverride] = useState(null);
  return (
    <>
    <HelmetHandler metadata={metadata} language={language} />
      {/* <DocumentMeta {...meta}> */}
      {navBarData && (
        <NavBarHandler
          data={{ ...navBarData, language }}
          innerPageNavData={innerPageNavData}
          regionData={regionData}
          applicationData={applicationData}
          languages={languages}
          language={language.id}
          setLanguage={setLanguage}
          // navCTAOverride={navCTAOverride}
        />
      )}
      
      <Routing
        routes={routes}
        language={language}
        languages={languages}
        region={region}
        setRegion={setRegion}
        regions={regions}
        setMetadata={setMetadata}
        setInnerPageNavData={setInnerPageNavData}
        fiveHundredData={fiveHundredData}
        fourZeroFourData={fourZeroFourData}
        NoRecordsFoundData={NoRecordsFoundData}
        languageEnabled={languageEnabled}
        // labels={labels}
        applicationData={applicationData}
        // setNavCTAOverride={setNavCTAOverride}
        setLanguage={setLanguage}
        status={status}
        setStatus={setStatus}
        // searchPageData={searchPageData}
      />
       
      {footerData && <FooterHandler data={footerData} status={status} />} 
    {/* </DocumentMeta> */}
    </>
  );
};

export default Template;
