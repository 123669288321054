import { useEffect, useState } from "react";
import regionLanguageMapping from "../../utils/helpers/regionLanguageMapping";
import selectedRegion from "../../utils/helpers/selectedRegion";
import locationRedirect from "../../utils/methods/locationRedirect";

const usePageFetch = (url, setStatus, setURL, regions, region, setRegion, location, history) => {
  // Fetches the data for normal pages
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);
  // the 'error' state is used only for testing purposes

  useEffect(() => {
    // FETCH THE DATA
    const getData = async () => {
      setStatus("loading");
      try {
        console.log('current region', region);
        let newRegion = JSON.stringify({id: region.id, name: region.name});
        let headers = selectedRegion().name ? {"Region": newRegion} : {};
        const urlParams = new URLSearchParams(window.location.search);
        const IsPreview = urlParams.get('IsPreview');
        if(IsPreview && IsPreview=='1'){
          headers.IsPreview = '1';
        }
        const response = await fetch(url, {headers});

        if (response?.status === 200) {
          // If you hit Drupal, get a response, but there is no matching node, the status is 200 before parsing regardless
          const data = await response.json();
          let newLanguages = ["en-us", "en-eu", "en-au", "de-de", "fi-fi", "sv-se", "no-no", "pl-pl"];
          let enRegions = ["us", "eu", "au"];
          const { pathname } = location;
          const splitPath = pathname.split("/");
          const apparentLanguageCode = splitPath[1];
          // if(!newLanguages.includes(apparentLanguageCode)) {
          if(!newLanguages.includes(apparentLanguageCode) || ((apparentLanguageCode.substring(0,2)=='en') && splitPath[2])) {
            if(!data.regions_code.includes(selectedRegion().region_code)) {
            
              let newRegion = regions?.filter(region => region.region_code === data.regions_code[0])[0];
              setRegion(newRegion);
              if (enRegions.includes(newRegion.region_code)) {
                const newPath = pathname.replace(apparentLanguageCode, `en-${newRegion.region_code}`);
                history.push(newPath);
              } 
              else {
                const newPath = pathname.replace(apparentLanguageCode, `${regionLanguageMapping[newRegion.name]+'-'+newRegion.region_code}`);
                history.push(newPath);
              }
            }
          }
        
          // after parsing with a status of 200, redirects and 404s will be labeled with a status_code property - odd but servicable
          if (data?.status_code === "301") {
            // This is responsible for handling external redirects
            const [redirect] = data?.redirect_destination;

            if (redirect) {
              locationRedirect(redirect);
              setError(true);

              // setError used only for test purposes
            } else {
              // if we get a 301, but there's no redirect address, set an error
              setResponse(null);
              setURL(null);
              setStatus("error");
              setError(true);
              // setError used only for test purposes
            }
          } else if (data?.status_code === "404") {
            // After parsing, if you get a response from Drupal but there was no matching node, you'll get a status code of 404 - it's weird IDK
            setStatus("404");
            setURL(null);
            setResponse(null);
            setError(true);
            // setError used only for test purposes
          } else if (data) {
            // parsed data that is from a valid endpoint doesn't have a status code like with 301/404
            setResponse(data);
            setURL(null);
          }
        } else {
          setStatus("error");
          setURL(null);
          setError(true);
        }
      } catch (e) {
        console.log(e);
        setStatus("error");
        setURL(null);
        setError(true);
        // setError used only for test purposes
      }
    };

    if (url) {
      getData();
    }
  }, [url, setStatus, setURL]);

  return { response, setResponse, error };
};

export default usePageFetch;
