import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import fetchNodeListings from "../../utils/helpers/fetchNodeListings";
import {useHistory} from "react-router-dom";

export default function Pagination({
  data, 
  setData, 
  setFeatured,
  setLoading,
  limit = 10, 
  fetchNodes = false, 
  totalRecords,
  type = "",
  category,
  search= "",
  tag="",
}) {
  let params = new URLSearchParams(window.location.search);
  let urlPage = parseInt(params.get("page") ? params.get("page") : fetchNodes ? 1 : 0);

  const [currentPage, setCurrentPage] = useState(urlPage);
  const [pageCount, setPageCount] = useState(Math.ceil(totalRecords / limit));
  const [nextLabelClass, setNextLabelClass] = useState("");
  const [previousLabelClass, setPreviousLabelClass] = useState("");
  let history = useHistory();

  useEffect(() => {
    if (fetchNodes) {
      setLoading(true);
      loadData(currentPage, category, search, tag, false);
    } else {
      let offset = currentPage * limit;
      setData(data.slice(offset, offset + limit));
    }
  }, [category, search]);

  const loadData = async(currentPage, category = null, search = null, isPageClickTrigerred) => {
    const page = isPageClickTrigerred ? parseInt(currentPage) + 1 : urlPage;
    var result = "";
    setCurrentPage(1);
    if (tag !== "" && tag !== null) {
      result = await fetchNodeListings(type, page, limit, history,'', "", "", tag);
    }
    else if (search !== null && search !== "") {
      result = await fetchNodeListings(type, page, limit, history, category?.id || '', "", search);
    } else {
      if (!isPageClickTrigerred && currentPage < 1 && (category === null || category === "All")) {
        result = await fetchNodeListings(type, page, limit, history);
        if (type === "blog_detail_page") {
          const featuredData = await fetchNodeListings(type, page, 2, history, category?.id, true);
          setFeatured(featuredData.data);
        }
      } else {
        result = await fetchNodeListings(type, page, limit, history, category?.id || '');
      }
    }
    //hide featured posts if category is selected or user is searching
    if (type === "blog_detail_page" && ((category && category.id) || search !== null || isPageClickTrigerred)) {
      setFeatured([]);
    } 

    let pagination = result.pagination;

    if (pagination) {
      setNextLabelClass(pagination.curr_page >= pagination.pages ? "d-none" : "");
      setPreviousLabelClass(pagination.curr_page <= 1 ? "d-none" : "");
      setPageCount(pagination?.pages);
      setCurrentPage(pagination.curr_page)
    }
   
    setData(result.data);
    setLoading(false);
  }

  function handlePageClick({ selected: selectedPage }) {
    window.scroll({top: 300, left: 0, behavior: 'smooth' });
    if (fetchNodes) {
      // setLoading(true);
      loadData(selectedPage, category, search, true);
    } else {
      let offset = (selectedPage) * limit;
      setData(data.slice(offset, offset + limit));
    }
  }

  return (
    <>
    {pageCount > 1 && 
      <ReactPaginate
        previousLabel={"Back"}
        nextLabel={"Next"}
        nextClassName={nextLabelClass}
        previousClassName={previousLabelClass}
        pageCount={pageCount}
        forcePage={currentPage - 1}
        onPageChange={handlePageClick}
        containerClassName={"pagination_calendar"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link "}
        activeClassName={"pagination__link active"}
        pageClassName={"pagination__link "}
      />
    }
    </>
  );
}
