import React, { useState } from "react";
import { FilterHero, Hero5 } from "luna-one";
import "../../CustomComponents/BlogCard2/BlogCard2.scss";
import Article from "../../CustomComponents/Article/Article";
import Pagination from "../../Pagination/Pagination";
import Loading from "../../Loading/Loading";
import chosenCategory from "../../../utils/helpers/chosenCategory";
import selectedRegion from "../../../utils/helpers/selectedRegion";

export default function ArticleLanding({content}) {

  const {
    categories,
    name,
    sub_header,
    select_categories_label,
    recent_articles_paginated,
    NoRecordsFoundData,
    applicationData
  } = content;

  let params = new URLSearchParams(window.location.search);
  let filterCats = [];
  if(selectedRegion().name=='North America' || selectedRegion().name=='Australia & New Zealand'){
    filterCats = ['Electric', 'Gas', 'Local Government', 'Water & Wastewater'];
    // filerCats = ['Stormwater', 'Wastewater', 'Wastewater & Stormwater'];
  }else if(selectedRegion().name=='Finland'){
    filterCats = ['Kaikki', 'Julkishallinto', 'Kaukolämpö', 'Sähkönjakelu', 'Vesihuolto'];
    // filerCats = ['Kuntablogi', 'Seuraava rasti', 'Tiedolla vai tuurilla'];
  }
  let categoryList = categories.map(category => {
    return category.name;
  });

  if(filterCats.length > 0) {
    categoryList = categoryList.filter(category => filterCats.includes(category));
  }
  
  const [data, setData] = useState(recent_articles_paginated.data);
  const [pagination, setPagination] = useState(
    recent_articles_paginated.pagination
  );
  const [selectedCategory, setSelectedCategory] = useState(
    chosenCategory(categories, params.get("category"))
  );
  const [loading, setLoading] = useState(false);
  const type = "article_detail_page";

  async function handleDropdownChange(selection, name) {
    console.log(selection);
    if (selection === "All" || selection === "hide") {
      setSelectedCategory(selection);
    } else {
      let selectedCategory = categories.filter((category) => {
        return category.name === selection;
      })[0];
      setSelectedCategory(selectedCategory);
    }
  }

  const dropdownOne = {
    options: categoryList,
    defaultText: applicationData.allLabelText || 'All',
    name: select_categories_label,
  };

  const dropdowns = [dropdownOne];

  return (
    <>
      <FilterHero
        header={name}
        text={sub_header}
        dropdowns={dropdowns}
        handleDropdownSelect={handleDropdownChange}
      />
    {loading && <Loading />}
      {data?.length > 0 && !loading && (
        <Article 
          learnMoreText={applicationData.learnMoreText}
          selectedCategory={selectedCategory} 
          content={data} 
        />
      )}
      {data?.length <= 0 && !loading && <Hero5 content={NoRecordsFoundData} />}
      <Pagination
        data={data}
        setData={setData}
        setLoading={setLoading}
        limit={pagination.itemsPerPage}
        fetchNodes={true}
        type={type}
        totalRecords={pagination.total}
        category={selectedCategory}
      />
    </>
  );
}
