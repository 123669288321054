import React,{useState} from 'react'
import './our_brands.css'
import logo1 from '../../../assets/icon/logo_1.png'
import logo2 from '../../../assets/icon/logo_2.png'
import logo3 from '../../../assets/icon/logo_3.png'
export default function OurBrands() {
  const [parallax, setParallax] = useState(true)
  let background = 'image'
  let bg = background
  let textColor = '#6a6976'

  return (
    <section
      className={`our_brands ${parallax ? 'parallax' : ''}`}
      style={{
        background: bg ? bg : '',
        backgroundImage: bg ? `url('${bg}')` : '',
      }}
    >
      <div className="our_brands_container">
        <h2 style={{ color: bg === 'white' || bg === '#fff' ? textColor : '' }}>
          Our Brands
        </h2>
        <p style={{ color: bg === 'white' || bg === '#fff' ? textColor : '' }}>
          Empower your organization with innovative infrastructure management
          solutions that support resilient networks and communities
        </p>
        <div className="brands_logo">
          <div>
            <img src={logo1} className="imgResponsive" alt="logo-brands" />
          </div>y
          <div>
            <img src={logo2} className="imgResponsive" alt="logo-brands" />
          </div>
          <div>
            <img src={logo3} className="imgResponsive" alt="logo-brands" />
          </div>
        </div>
      </div>
    </section>
  )
}
