import { useState, useEffect } from "react";

const useRegion = () => {

  const [region, setRegion] = useState(getRegion());

  useEffect(() => {
    if(region && region !== null) {
      localStorage.setItem('region', JSON.stringify(region))
    }
  }, [region])

  function getRegion() {
    return JSON.parse(localStorage.getItem('region'));
  }

  return {region, setRegion};
}

export default useRegion;
