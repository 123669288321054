import formatLink from "../../helpers/formatLink";

const formatCTAs = (ctas = []) => {
  if (ctas?.length) {
    return {
      ctaOne: {
        url: ctas[0]?.url
          ? formatLink(ctas[0].url)
          : null,
        text: ctas[0]?.text,
      },
      ctaTwo: {
        url: ctas[1]?.url
          ? formatLink(ctas[1].url)
          : null,
        text: ctas[1]?.text,
      },
    };
  } else {
    return null;
  }
};

export default formatCTAs;
