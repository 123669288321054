import React from "react";
import Resource from './Resource'
export default function ResourcesCard(props) {
  const{cards,header} = props.content

  
  return (

      <div
        className="ter-list-feat-main false ter-list-feat-main--modal-hidden resource_main"
        style={{ zIndex: "auto", backgroundColor: "rgb(0, 45, 91)" }}
      >
        <div
          className="react-reveal"
          style={{
            animationFillMode: "both",
            animationDuration: "1000ms",
            animationDelay: "0ms",
            animationIterationCount: 1,
            opacity: 1,
            animationName: "react-reveal-290074148411893-1",
          }}
        >
          <h2
            className="ter-list-feat-main__header ter-feat__header"
            style={{ color: "rgb(255, 255, 255)" }}
          >
           {header}
          </h2>
       
        </div>
        <section className="ter-list-feat-six" style={{ zIndex: "auto" }}>
          {cards.map((index,key)=>{
            return(<Resource key={key} content={index}/>)
          })}
        </section>
      </div>

  );
}
