import formatCTAs from "../formatCTAs";

const formatFeatFour = (
  featFourData = {},
  languageCode,
  languageEnabled,
  labels,
  fpoBox,
) => {
  const data = featFourData || {};
  const theme = data.theme;

  const ctas = formatCTAs(
    data.ctas,
    languageCode,
    languageEnabled,
  );

  const content = {
    header: data.intro_header,
    subHeader: data.intro_description,
    ctas,
  };

  const type = data.type;
  const anchor =
    data.anchor_toggle && data.anchor_text ? data.anchor_text : null;

  return {
    content,
    theme,
    type,
    anchor,
  };
};

export default formatFeatFour;
