import formatLink from "../../../helpers/formatLink";

const formatHeadShotdumplings = (
  dumplingData = [],
  fpoBox,
) => {
  const data = dumplingData || [];

  return data.map((dumpling) => {
    return {
      content: {
        link: dumpling?.link?.url
          ? {
              url: formatLink(
                dumpling.link.url,
              ),
            }
          : {},
        header: dumpling?.header,
        subHeader: dumpling?.subtitle,
        text: dumpling?.description,
        image: {
          url: dumpling?.image?.source || fpoBox,
          altText: dumpling?.image?.alt || "logo",
        },
      },
      filter: "default",
    };
  });
};

export default formatHeadShotdumplings;
