import React from "react";
import Parallex from "./Parallex";

export default function ParalexLink(props) {
 
  const { background_image, cards, header,theme } = props.content;
  
  return (
    <div id="root">
      <div
        className="ter-list-feat-main paralex-link-main false ter-list-feat-main--modal-hidden"
        style={{
          backgroundImage: `url('${background_image?.source}')`,
          zIndex: "auto",
          backgroundAttachment: "fixed",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          className="react-reveal"
          style={{
            animationFillMode: "both",
            animationDuration: "0ms",
            animationDelay: "0ms",
            animationIterationCount: 1,
            opacity: 1,
            animationName: "react-reveal-466845260446205-1",
          }}
        >
          <h2
            className="ter-list-feat-main__header ter-feat__header"
            style={{ color: theme?.color }}
          >
            {header}
          </h2>
        </div>

        <section className="ter-list-feat-seven">
          {cards.map((index, key) => {
            return <Parallex key={key} content={index} theme ={theme}/>;
          })}
        </section>
      </div>
    </div>
  );
}
