import { useState, useEffect } from "react";
import regionLanguageMapping from "../../utils/helpers/regionLanguageMapping";

const useLanguage = (languages, region) => {
  const [language, setLanguage] = useState(null);

  function getLanguage() {
    return JSON.parse(localStorage.getItem('language'));
  }

  // function setDefaultLanguage() {
  //   const browserLanguage =
  //     window?.navigator?.userLanguage?.substring(0, 2) ||
  //     window?.navigator?.language?.substring(0, 2);

  //   var result = languages?.filter((lang) => {
  //     return browserLanguage === lang.id;
  //   });
  //   if (result.length) {
  //     setLanguage(result[0]);
  //   } else {
  //     const defaultLanguage = languages.filter((language) => {
  //       return language.default === true;
  //     })[0];

  //     setLanguage(defaultLanguage);
  //   }
  // }

  useEffect(() => {
    //select already selected language otherwise select region default language
    if (getLanguage()) {
      setLanguage(getLanguage());
    } else if (region && region !== null && languages) {
      const langauge = languages.filter((language) => {
        return language.id === regionLanguageMapping[region?.name];
      })[0];

      setLanguage(langauge);
    }
    //   if (languages !== null) {
    //   // console.log(languages);
    //   setDefaultLanguage();
    // }
    // }
  }, [languages, region]);

  return { language, setLanguage };
};

export default useLanguage;
