import React from 'react'
import {FilterHero} from 'luna-one'
export default function Filterhero() {
  // handleDropdownChange = (selection, name) => {
  //   this.setState({[name]: selection})
  // }
  
  // handleCheckboxChange = (bool, name) => {
  //   this.setState({[name]: bool})
  // }
  
  const header = 'I am a header'
  const text = 'Tempor ex esse ipsum sit eiusmod dolor mollit cupidatat elit cupidatat pariatur commodo non est.'
  
  const dropdownOne = {
    options: ["trade one", "trade two", "trade three"],
    defaultText: "All",
    name: "Trade"
  };
  const dropdownTwo = {
    options: ["category one", "category two", "category three"],
    defaultText: "All",
    name: "Category"
  };
  const dropdowns = [dropdownOne, dropdownTwo]
  
  const checkboxOne = {
    name: "Checkbox One"
  };
  const checkboxTwo = { name: "Checkbox Two" };
  const checkboxes = [checkboxOne, checkboxTwo];
  
  return (
    <FilterHero
      header={header}
      text={text}
      dropdowns={dropdowns}
      checkboxes={checkboxes}
      handleDropdownChange={this.handleDropdownChange}
      handleCheckboxChange={this.handleCheckboxChange}
      backgroundColor='#5e5e5e'
    />
  )
}
