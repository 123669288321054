import { useEffect } from "react";
import { Events, animateScroll } from "react-scroll";

const useScrollTo = () => {
  useEffect(() => {
    const registerScrollTo = () => {
      // This tracks movement from react-scroll and determines whether or not to add an extra 100px of distance between the scrollY and the element we're scrolling to so that when the navbar expands during upward scrolling it doesn't cover up the top of the container we've sxrolled to.

      // Determines whether or not the scroll action is going up or down the page based on current scrollY and destination offsetTop
      let scrollUp = false;

      // Determines if the scroll event is a function of react-scroll by clicking a navbar link or is artificially created during the 'end' function when scrolling up
      let scrollEvent;

      // This action occurs at the beginning of the scroll action, after the link click but before the scroll happens
      // It determines if we are scrolling up/down and sets scrollEvent to true or false so that we aren't repeatedly moving up the DOM over and over
      Events.scrollEvent.register("begin", (to, element) => {
        if (element && element.offsetTop) {
          if (element.offsetTop < window.scrollY) {
            scrollEvent = true;
            scrollUp = true;
          } else {
            scrollUp = false;
          }
        }
      });

      // This action occurs at the end of the scroll action
      // Checks to see if we're scrolling up and if the scrollEvent is kicked off by a non-artificial scroll event and moves view up an extra 150px
      Events.scrollEvent.register("end", (to, element) => {
        if (scrollUp && scrollEvent) {
          scrollEvent = false;
          animateScroll.scrollTo(window.scrollY - 100, {
            duration: 500,
            delay: 0,
            smooth: true,
            offset: 0,
          });
        }
      });
    };

    registerScrollTo();

    return () => {
      const unregisterScrollTo = () => {
        Events.scrollEvent.remove("begin");
        Events.scrollEvent.remove("end");
      };

      unregisterScrollTo();
    };
  }, []);
};

export default useScrollTo;
