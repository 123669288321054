import React, { useState, useEffect } from "react";

export default function NewsSection(props) {
  console.log(props.content)
  const [animation, setanimation] = useState(false);
  const {
    button,
    header,
  } = props.content;

  useEffect(() => {
    setanimation(true);
  }, [animation]);

  return (
    <div id="root" className="newsec">
      <div className="ter-list-feat-main false ter-list-feat-main--modal-hidden">
        <div
          className=""
          style={{
            animationFillMode: "both",
            animationDuration: "0ms",
            animationDelay: "0ms",
            animationIterationCount: "1",
            opacity: "1",
            animationName: "-606937183771701-1",
          }}
        >
          <h2
            className="ter-list-feat-main__header ter-feat__header"
            style={{ color: "rgb(54, 53, 69)" }}
          >
            {header}
          </h2>
        </div>

        <section className="ter-list-feat-six cont">
          {/* {list.map((index,key)=>{
           return(animation ?  <Fade key={key} bottom><NewsCard content={index}/></Fade>:<NewsCard key={key} content={index}/>) 
          })} */}
          {button && <a
            aria-current="page"
            className="ter-button ter-button--secondary--1 active"
            href={button?.url}
            style={{ textDecoration: "none", width: "fit-content" }}people_card_body
          >
            {button?.text}
          </a>}
        </section>
      </div>
    </div>
  );
}
