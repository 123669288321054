import React from "react";
import "./ContentSocial.css";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";
import applicationData from "../../../utils/helpers/applicationData";
import { Link } from "react-router-dom";

export default function ContentSocial({ content, tags, publishedDate }) {
  const path = window.location.pathname.split("/");
  const blogLandingUrl =
    path[1] && path[2] ? "/" + path[1] + "/" + path[2] : "/" + path[1];

  const getTags = () =>
    tags.map((tag, key) => {
      return (
        <>
          <Link to={`${blogLandingUrl}?tag=${tag.id}`} className="tag">
            {tag.name}
          </Link>{" "}
          {key + 1 < tags.length ? "," : ""}
        </>
      );
    });

  return (
    <>
      <section
        style={{ paddingTop: "10vh" }}
        className="ter-filter-hero content_social"
        id="Filter-Hero Header"
      >
        <div className="content_inner blog_detail_page">
          <div className="social_icon">
            <ul className="fixed_box sticky">
              <FacebookShareButton quote="Facebook share">
                <FacebookIcon size={"2rem"} round />
              </FacebookShareButton>

              <TwitterShareButton>
                <TwitterIcon size={"2rem"} round />
              </TwitterShareButton>

              <LinkedinShareButton
                title="LinkedIn Share"
                windowWidth={750}
                windowHeight={600}
              >
                <LinkedinIcon size={"2rem"} round />
              </LinkedinShareButton>
            </ul>
          </div>

          <div className="content section">
            {tags && tags.length > 0 && <p>Tags: {getTags()}</p>}
            {publishedDate && (
              <p>
                {applicationData()?.publishedDateText || "Published Date"}:{" "}
                {publishedDate}
              </p>
            )}
            <div
              style={{ width: "100%" }}
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </div>
        </div>
      </section>
    </>
  );
}
