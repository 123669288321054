import { useEffect, useState } from "react";
import formatContentSections from "../../utils/methods/formatContentSections";
const usePageFormat = (
  response,
  setMetadata,
  setStatus,
  setResponse,
  setInnerPageNavData,
  language,
  applicationData
) => {
  // Formats the data for normal pages
  const [content, setContent] = useState(null);
  useEffect(() => {
    // FORMAT THE DATA
    const formatData = () => {
      const responseData = JSON.parse(JSON.stringify(response));
  
      const otherPageTemplates = [
        "blog_detail_page",
        "blog_landing_page",
        "events_landing_page",
        "article_landing_page",
        "article_detail_page",
        "case_studies_page",
        "case_studies_detail_page",
        "news_landing_page",
        "news_detail_page",
        "product_landing_page",
        "product_detail_page",
        "webinar_landing_page",
        "webinar_detail_page",
        "industries",
        "software_downloads_page",
      ];
      try {
        if (responseData) {
          switch (responseData.status_code) {
            case "200":
              let formattedSections;

              const contentSections = responseData?.content_sections.main_content 
                ? formatContentSections(
                responseData?.content_sections,
                language,
                true,
                []
              ) : [];

              if (otherPageTemplates.includes(responseData.type)) {
                formattedSections = responseData;
                formattedSections.content_sections = contentSections;
              } else {
                formattedSections = contentSections;
              }
              
              if (responseData.metatags) {
                setMetadata(responseData.metatags);
              }
              const innerPageNavData = {
                pageTitle: responseData?.page_name || "",
                // links,
              };
            
              //sending application data for headings and other purposes
              formattedSections.applicationData = applicationData;
              setInnerPageNavData(innerPageNavData);
              setContent(formattedSections);
              setResponse(null);
              setStatus(responseData.status_code);
              window.prerenderReady = true;
              break;
            case "404":
              setResponse(null);
              setStatus(responseData.status_code);
              break;
            default:
              setResponse(null);
              setStatus("404");
              break;
          }
        }
      } catch (e) {
        console.log(e, "formatting error");
        setStatus("error");
        setResponse(null);
        setContent(null);
      }
    };
    if (response) {
      formatData();
    }
  }, [response, formatContentSections, setMetadata]);
  return { content, setContent };
};
export default usePageFormat;
