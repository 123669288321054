import { useEffect, useState } from "react";
import backendURL from "../../utils/helpers/backendURL";
const useSearchFetch = (
  searchTerm,
  language,
  setMetadata,
  setInnerPageNavData,
  languageEnabled,
  searchPageData
) => {
  // THIS IS CURRENTLY NOT TESTED AND REQUIRES A BREAKING OUT OF VARIOUS USEEFFECTS IF IT IS GOING TO BE //

  // Fetch and set data for search results
  const [url, setURL] = useState(null);
  const [searchPageURL, setSearchPageURL] = useState(null);
  const [response, setResponse] = useState(null);
  const [content, setContent] = useState(null);
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    const getURL = () => {
      const searchURL = `${backendURL()}${
        languageEnabled ? `/${language}` : ""
      }/api-v1/FetchSearchResults/0/500/${searchTerm}`;
      setURL(searchURL);

      const searchPageURL = `${backendURL()}${
        languageEnabled ? `/${language}` : ""
      }/api-v1/GetNodeByURL/search/$`;
      setSearchPageURL(searchPageURL);
    };

    if (searchTerm || language) {
      getURL();
    }
  }, [searchTerm, language]);

  useEffect(() => {
    const fetchSearchPageData = async () => {
      const response = await fetch(searchPageURL);
      const data = await response.json();

      if (data?.[0]?.metatags) {
        setMetadata(data[0].metatags);
      }

      if (data?.[0]?.page_name) {
        setInnerPageNavData({ pageTitle: data[0].page_name, links: [] });
      }
    };

    if (searchPageURL) {
      fetchSearchPageData();
    }
  }, [searchPageURL, setMetadata, setInnerPageNavData]);

  useEffect(() => {
    // make the fetch calls

    const fetchData = async () => {
      setStatus("loading");

      try {
        // the searchRespoanse is the Drupal response on the search query, will an object with an array of results that we iterate over and build Terra Cards with
        const searchResponse = await fetch(url);
        const searchData = await searchResponse.json();

        // the searchPageData object is comes from the siteApplicationData fetch during initial application load and includes mostly labels like 'Learn More', 'Search', 'Results', etc.

        setResponse({ searchData });
        setURL(null);
      } catch (e) {
        setStatus("error");
        setURL(null);
        console.log(e);
      }
    };

    if (url) {
      fetchData();
    }
  }, [url]);

  useEffect(() => {
    // format fetched data

    const formatResponse = () => {
      // using optional chaining weeeeeeee!

      const {
        searchResultsLabel,
        searchAgain,
        noResultsLabel,
        submitText,
        learnMoreLabel,
        images,
      } = searchPageData;

      const searchCount = response?.searchData?.count;

      // const metadata = {};
      // const pageTitle = "Search results";

      if (searchCount === 0) {
        setContent({ noResultsLabel, images, searchAgain, submitText });
        setStatus("success");
        setResponse(null);
        return;
      } else {
        const results = response?.searchData?.results
          ?.filter((result) => result.teaser_title && result.teaser_url)
          .map((result) => {
            return {
              header: result.teaser_title,
              text: result.teaser_desc,
              image: {
                url: result?.teaser_img?.source,
                altText: result?.teaser_img?.alt,
              },
              link: {
                text: learnMoreLabel,
                url: result.teaser_url,
              },
            };
          });

        setContent({ results, searchResultsLabel, images });
        setStatus("success");
        setResponse(null);
      }
    };

    if (response && response.searchData && searchPageData) {
      formatResponse();
    } else if (response) {
      setStatus("error");
      console.log("Error - searchData or searchPageData is null");
    }
  }, [response, searchPageData]);

  return { content, status };
};

export default useSearchFetch;
