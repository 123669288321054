import { Hero1 } from "luna-one";
import AboutAuthor from "../../CustomComponents/About-Author/AboutAuthor";
import ContentSection from "../../CustomComponents/Content-Section/ContentSection";
import Stack from "../../Stack/Stack";

export default function CaseStudyDetail({content}) {
  const {
    body,
    image,
    name,
    sub_header,
    content_sections,
    created_at,
    about_author,
    hide_author_info
  } = content;

  const images = {
    desktop: {
      url: image?.source,
      altText: image?.alt,
    },
    tablet: {
      url: image?.source,
      altText: image?.alt,
    },
    mobile: {
      url: image?.source,
      altText: image?.alt,
    },
  };

  const theme1 = {
    backgroundColor: "#ffffff",
  };

  const heroContent = {
    images,
    header: name,
    text: sub_header,
    theme: theme1,
  };

  return (
    <>
      <Hero1 content={heroContent} motion />
      <ContentSection publishedDate={created_at} content={ body[0]?.value }/>
      {content_sections.length > 0 && <Stack publishedDate={created_at} content={content_sections}/>}
      {about_author && !hide_author_info && <AboutAuthor content={about_author} />}
    </>
  );
}
