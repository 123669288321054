// TODO add real backend url and env variables

const chosenCategory = (categories, categoryId) => {
  if (categoryId !== null) {
    let selectedCategory = categories.filter((category) => {
      return category.id === categoryId;
    });
    return selectedCategory.length > 0 ? selectedCategory[0] : null;
  }
  return null;
}

export default chosenCategory;
