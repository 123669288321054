import React from "react";
import { Link } from "react-router-dom";

export default function ContactInfo(props) {
  const { contact_link_items, contact_list_items,theme } = props.content;

  return (
    <div className="contact_sec">
      <div 
        className="false ter-list-feat-main--modal-hidden pad" 
        style={{backgroundColor: `${theme.backgroundColor}`,color: `${theme.color}`}}>
        {contact_link_items.map((index) => {
          let links = "";
          if (index.links.length === undefined) {
            links = [index.links]
          } else {
            links = index.links;
          }

          return (
            <>
              <h3>{index.header}</h3>
              {links?.map((index) => {
                return (
                  <p style={{marginTop:'15px !important'}}>
                    <Link to={index.url}>{index.text || 'Contact Link'}</Link>
                  </p>
                );
              })}
            </>
          );
        })}
        {contact_list_items.map((index) => {
          return (
            <>
              <h4>{index.header}</h4>
              <section className="ter-list-feat-six cont">
                {index.cards.map((index) => {
                  return (
                    <div
                      className=" ter-list-feat-six__card"
                      style={{
                        animationFillMode: "both",
                        animationDuration: "0ms",
                        animationDelay: "0ms",
                        animationIterationCount: "1",
                        opacity: "1",
                        animationName: "-606937183771701-1",
                      }}
                    >
                      <p>{index.heading}</p>
                      <p
                        dangerouslySetInnerHTML={{ __html: index.description }}
                      ></p>
                    </div>
                  );
                })}
              </section>
            </>
          );
        })}
      </div>
    </div>
  );
}
