import React from "react";
import { Link } from "react-router-dom";
import applicationData from "../../../utils/helpers/applicationData";

export default function NavigationLinks({ navigationLinks }) {
  const { next, previous } = navigationLinks;
  const { nextLabel, previousLabel } = applicationData();

  return (
    <section style={{ backgroundColor: "#FFF" }}>
      <div class="navigation_links content_inner">
        <li style={{ flex: "auto" }}>
          <Link to={previous}>❮ {previousLabel || "Previous"}</Link>
        </li>
        <li>
          <Link to={next}>{nextLabel || "Next"} ❯</Link>
        </li>
      </div>
    </section>
  );
}
