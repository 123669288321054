const formatFeatThree = (featThreeData) => {
  const data = featThreeData || {};

  const header = data?.header;
  const subHeader = data?.subHeader;
  
  const largeImage = {
    url: data?.image?.source,
    altText: data?.image?.alt,
  };
  const images = {
    desktop: largeImage,
    mobile: data?.image?.source
      ? {
          url: data?.image?.source,
          altText: data?.image?.alt,
        }
      : largeImage,
  };

  const content = { header, subHeader, images };
  const theme = data.theme;
  const type = data.type;
  const anchor =
    data.anchor_toggle && data.anchor_text ? data.anchor_text : null;
  return { content, theme, type, anchor };
};

export default formatFeatThree;
