import formatCTAs from "../formatCTAs";

const formatHeroFour = (
  heroFourData,
  labels,
  fpoBox,
) => {
  const data = heroFourData || {};
  const ctas = formatCTAs(
    data.ctas,
  );

  // TODO bigger fpo image
  const content = {
    header: data.header,
    subHeader: data.subheader,
    text: data.description,
    image: {
      src: data?.image_large?.source || fpoBox,
      alt: data?.image_large?.alt,
    },
    ctas,
  };

  const theme = data.theme;

  return {
    theme,
    content,
    type: data.type,
  };
};

export default formatHeroFour;
