import formatCTAs from "../formatCTAs";

const formatHeroSix = (heroSixData, labels, fpoBox) => {
  const data = heroSixData || {};

  const header = data.header;
  const text = data.subheader;
  const ctas = formatCTAs(data.ctas);

  const imageLarge = {
    src: data?.image_large?.source,
    alt: data?.image_large?.alt,
  };

  const images = {
    desktop: { imageLarge },
    mobile: data?.image_small?.source
      ? {
          src: data?.image_small?.source,
          alt: data?.image_small?.alt,
        }
      : imageLarge,
    tablet: data?.image_medium?.source
      ? {
          src: data?.image_medium?.source,
          alt: data?.image_medium?.alt,
        }
      : imageLarge,
  };

  const content = { header, text, ctas, images };
  const theme = data.theme;
  return { content, theme, type: data.type || "hero_6" };
};

export default formatHeroSix;
