import { useState, useEffect } from "react";

const useFooterResponse = (url) => {
  // Responsible for fetching and formatting the footer data
  const [footerResponse, setFooterResponse] = useState(null);

  useEffect(() => {
    // set response
    try {
      
      const getData = async () => {
        const response = await fetch(url);
        const data = await response.json();
     
        setFooterResponse(data);
      };

      if (url) {
        getData();
      }
    } catch (e) {
      console.log(e);
    }
  }, [url]);

  return { footerResponse, setFooterResponse };
};

export default useFooterResponse;
