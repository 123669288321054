import React from "react";
import { Helmet } from "react-helmet";
import favicon from "../../assets/images/favicon.ico";

const HelmetHandler = ({ metadata = {}, language }) => {
  const { title, canonical_url, description } = metadata;

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title || "Trimble Utilities & Public Administration"} />
      <meta property="og:description" content={description || "This is a page by Trimble"} />
      <meta property="og:url" content={canonical_url} />
      <link
        rel="canonical"
        href={window.location.href}
      />

      {description && <meta name="description" content={description} />}
      {/* <html lang={language || "en-US"} amp />
      <meta charset="utf-8" /> */}
      {/* <link
        id="favicon"
        rel="alternate"
        href={favicon}
        type="image/x-icon"
      /> */}
      {/* og tags */}

    </Helmet>
  );
};

export default HelmetHandler;
