import React from "react";

import { Hero1 } from "luna-one";
import Stack from "../../Stack/Stack";

export default function ProductDetail({content}) {
  
  const {
    content_sections,
    image,
    name,
    sub_header,
  } = content;

  const images = {
    desktop: {
      url: image?.source,
      altText: image?.alt,
    },
    tablet: {
      url: image?.source,
      altText: image?.alt,
    },
    mobile: {
      url: image?.source,
      altText: image?.alt,
    },
  };
  const theme1 = {
    backgroundColor: "#ffffff",
  };

  const heroContent = {
    images,
    header: name,
    text: sub_header,
    theme: theme1,
  };

  return (
    <main>
      <Hero1 content={heroContent} motion />
      {content_sections.length > 0 && <Stack content={content_sections}/>}
    </main>
  );
}
