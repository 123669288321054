import React from "react";
import dummyImg from "../../../assets/images/dummy/no_image.jpg";
import calendar_icon from "../../../assets/images/calendar.svg";
import dummyImg2 from "../../../assets/images/dummy/no_image_2.jpg";
import generateLink from "../../../utils/helpers/generateLink";
import displaySelectedCategory from "../../../utils/helpers/displaySelectedCategory";
import "./Article.css";

export default function Article({
  header,
  subHeader,
  content,
  featured,
  selectedCategory,
  showDate = false,
  learnMoreText
}) {

  return (
    <section className="blog_listing">
      {header && (
        <h2 style={{ marginBottom: "10px" }} className="ter-feat-one__header">
          {header}
        </h2>
      )}
      {subHeader && (
        <p
          style={{ textAlign: "center", marginBottom: "50px" }}
          className="ter-hero-one__text"
        >
          {subHeader}
        </p>
      )}
      <div className="feature_blog_box">
        {featured?.map((index, key) => {
          return (
            <div className="blog_cards_box">
              <div className="blog_card_inner">
                <div className="feature_img">
                  <label className="feature_label">Featured</label>
                  <img
                    src={
                      index?.image?.source ? index?.image?.source : dummyImg2
                    }
                    alt={index?.image?.alt}
                    className="imgResponsive"
                  />
                </div>
                <div className="blog_card_inner_body">
                  <label>
                    {displaySelectedCategory(
                      index.categories,
                      selectedCategory
                    )}
                  </label>
                  <h4>{index?.name.slice(0, 30)}</h4>
                  <p>{index.summary?.slice(0, 150) || "No Description"}</p>
                </div>
              </div>
              {generateLink(
                index?.read_more_url,
                learnMoreText || "Learn More",
                "blue_border_btn"
              )}
            </div>
          );
        })}
      </div>
      <div className="blog_cards_container">
        {content?.map((index, key) => {
          return (
            <div key={key} className="blog_cards_box">
              <div className="blog_card_inner">
                <div className="feature_img">
                  <img
                    src={index.image?.source || dummyImg}
                    alt={index.image?.alt}
                    className="imgResponsive"
                  />
                </div>
                <div className="blog_card_inner_body">
                  <label>
                    {displaySelectedCategory(
                      index.categories,
                      selectedCategory
                    )}
                  </label>
                  <h4>{index?.name}</h4>
                  {showDate && index.created_at && (
                    <span className="date_box">
                      <img src={calendar_icon} alt="calender icon" />
                      {index.created_at}
                    </span>
                  )}
                  <p>{index.summary?.slice(0, 150) || "No Description"}</p>
                </div>
              </div>
              {generateLink(
                index?.read_more_url,
                learnMoreText || "Learn More",
                "blue_border_btn"
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
}
